/* You can add global styles to this file, and also import other style files */
/*
Default Style
============================*/
:root {
  --bodyFontFamily: "Open Sans", sans-serif;
  --headingFontFamily: "Catamaran", sans-serif;
  --mainColor: #1d42d9;
  --bodyColor: #7a7e9a;
  --headingColor: #293a5c;
  --whiteColor: #ffffff;
  --blackColor: #000000;
  --darkBlueColor: #051242;
  --boxShadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  --transition: all 0.5s;
  --borderRadius: 4px;
}

body {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.8;
  font-family: var(--bodyFontFamily);
  color: var(--bodyColor);
}

a {
  display: inline-block;
  transition: var(--transition);
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: var(--headingColor);
}
a:focus {
  text-decoration: none;
}

button {
  outline: 0 !important;
  box-shadow: none;
  border: none;
}
button:focus {
  box-shadow: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headingColor);
  font-family: var(--headingFontFamily);
  font-weight: 700;
}

h3 {
  font-size: 19px;
  line-height: 2.5;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

p {
  font-size: 15px;
  margin-bottom: 15px;
  text-align: left;
}
p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 25%;
  height: auto;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.form-control {
  height: 50px;
  color: var(--headingColor);
  border: 1px solid #e8e8e8;
  background-color: transparent;
  border-radius: 0;
  padding: 0;
  font-size: 15px;
  padding: 10px 20px;
  width: 100%;
}
.form-control::placeholder {
  color: #495057;
  font-size: 12px;
}
.form-control:focus {
  color: #fff;
  background-color: transparent;
  box-shadow: unset;
  outline: 0;
  border: 1px solid var(--mainColor);
}

.form-control:hover:focus,
.form-control:focus {
  box-shadow: unset;
}

textarea.form-control {
  height: auto;
  height: 250px;
}

.ptb-100 {
  padding-top: 35px;
  padding-bottom: 46px;
}

.pt-100 {
  padding-top: 55px;
}

.pb-100 {
  padding-bottom: 50px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 50px;
}

.pb1-70 {
  margin-top: 50px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-minus-70 {
  margin-top: -70px;
}

.mb-minus-70 {
  margin-bottom: -70px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.bg-color {
  background: aliceblue;
}

@keyframes rotaed360 {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
@keyframes animationFramesOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
/*
Go Top Style*/
.go-top {
  position: fixed;
  cursor: pointer;
  top: 93%;
  right: -10%;
  background-color: var(--mainColor);
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  opacity: 0;
  visibility: hidden;
  transition: 0.9s;
}
.go-top i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: var(--whiteColor);
  transition: 0.5s;
  font-size: 20px;
}
.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}
.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--mainColor);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.go-top:hover {
  color: var(--whiteColor);
  background-color: var(--headingColor);
}
.go-top:hover::before {
  opacity: 1;
  visibility: visible;
}
.go-top:hover i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:hover i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.go-top:focus {
  color: var(--whiteColor);
}
.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}
.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}
.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.go-top.active {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  right: 3%;
  top: 93%;
}

/** Section tittle Css **/
.section-title {
  position: relative;
  max-width: 695px;
  margin: -8px auto 60px;
  text-align: center;
}
.section-title span {
  color: var(--mainColor);
  font-size: 14px;
  position: relative;
  display: inline-block;
  margin-bottom: 8px;
}
.section-title h2 {
  font-size: 40px;
  text-transform: initial;
  color: var(--headingColor);
  margin-bottom: 10px;
}

/** Box Btn Css **/
.box-btn {
  font-size: 16px;
  color: var(--whiteColor);
  padding: 10px 21px;
  line-height: 1;
  transition: var(--transition);
  text-transform: capitalize;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  background-image: linear-gradient(90deg, #b4c2e2, #4f54d7);
}
.box-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 50%;
  background-color: var(--darkBlueColor);
  z-index: -1;
  transition: all 0.5s;
}
.box-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 100%;
  width: 100%;
  height: 50%;
  background-color: var(--darkBlueColor);
  z-index: -1;
  transition: all 0.5s;
}
.box-btn.border-btn {
  background-color: transparent;
}
.box-btn:hover {
  color: var(--whiteColor);
}
.box-btn:hover::before {
  left: 0;
}
.box-btn:hover::after {
  right: 0;
}

/** Line Btn Css **/
.line-bnt {
  font-size: 14px;
  color: var(--headingColor);
  font-weight: 600;
  line-height: 1;
}
.line-bnt:hover {
  letter-spacing: 1px;
  color: var(--headingColor);
}

/** Preloader Css **/
.loader-content {
  background: var(--darkBlueColor);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
  text-align: center;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}
.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColor);
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}
@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube2::before {
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube3::before {
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube4::before {
  animation-delay: 0.9s;
}

/*
Header Area Css
======================================================*/
.header-area {
  position: relative;
  padding: 5px 0;
  overflow: hidden;
  background-image: linear-gradient(90deg, #e4e4e4, #bebfdd);
}
.header-area .header-content-right {
  position: relative;
  display: inline-flex;
}
.header-area .header-content-right .header-contact {
  padding: 0;
  margin: 0;
  position: relative;
}
.header-area .header-content-right .header-contact li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  margin-right: 20px;
  font-weight: 500;
}
.header-area .header-content-right .header-contact li a {
  color: var(--darkBlueColor);
}
.header-area .header-content-right .header-contact li a i {
  position: relative;
  top: 2px;
  margin-right: 5px;
  font-size: 16px;
  color: var(--mainColor);
}
.header-area .header-content-right .header-contact li a:hover {
  color: var(--mainColor);
}
.header-area .header-content-right .header-contact li:last-child {
  margin-right: 0;
}
.header-area .header-content-right .header-contact::before {
  position: absolute;
  width: 1px;
  height: 24px;
  content: "";
  background: var(--mainColor);
  right: -50px;
  top: 2px;
}
.header-area .header-content-right .header-social {
  padding: 0;
  margin: 0;
  margin-left: 20px;
  padding-top: 7px;
}
.header-area .header-content-right .header-social li {
  display: inline-block;
  position: relative;
  padding-left: 10px;
}
.header-area .header-content-right .header-social li a {
  font-size: 16px;
  color: var(--mainColor);
}
.header-area .header-content-right .header-social li a:hover {
  color: var(--mainColor);
  transform: translateY(-2px);
}
.header-area .header-content-right .header-social::before {
  display: none !important;
}

/*
header-2 Area Css
======================================================*/
.header-2 {
  background: var(--mainColor);
}
.header-2.three {
  background: #000b2f;
}
.header-2 .header-content-right .header-contact li {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  font-size: 16px;
  margin-right: 20px;
}
.header-2 .header-content-right .header-contact li a {
  color: var(--whiteColor);
}
.header-2 .header-content-right .header-contact li a i {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 4px;
  color: var(--whiteColor);
  font-size: 18px;
}
.header-2 .header-content-right .header-contact li a:hover {
  color: var(--whiteColor);
}
.header-2 .header-content-right .header-contact::before {
  display: none;
}
.header-2 .header-content-right .header-social {
  padding: 0;
  margin: 0;
  margin-left: 10px;
}
.header-2 .header-content-right .header-social li {
  display: inline-block;
  position: relative;
  padding-left: 10px;
}
.header-2 .header-content-right .header-social li a {
  font-size: 18px;
  color: var(--whiteColor);
  width: 35px;
  height: 35px;
  border: 1px solid var(--whiteColor);
  border-radius: 50%;
  line-height: 37px;
  text-align: center;
  transition: 0.3s;
  transition: 0.3s;
}
.header-2 .header-content-right .header-social li a:hover {
  background: var(--whiteColor);
  color: #1e2a55;
}
.header-2 .header-content-right .header-social li:first-child {
  padding-left: 0;
}

/*
Navbar Area Css
======================================================*/
.navbar-area {
  background-color: transparent;
  transition: 0.3s;
  padding: 0;
}
.navbar-area.three .main-nav {
  background-color: var(--darkBlueColor);
}
.navbar-area .main-nav {
  background: #000;
  position: absolute !important;
  top: 65px !important;
  left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  z-index: 999;
  height: auto;
}
.navbar-area .main-nav.sticky {
  position: fixed !important;
  top: 0 !important;
  width: 100%;
  left: 0;
  z-index: 999;
  animation: fadeInDown 500ms ease-in-out;
  transition: 0.3s;
  box-shadow: 0 1px 10px rgba(221, 221, 221, 0.2);
  background: var(--darkBlueColor) !important;
}
.navbar-area .main-nav {
  background: transparent;
  transition: 0.4s;
}
.navbar-area .main-nav .navbar {
  transition: 0.4s;
  height: 45px;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}
.navbar-area .main-nav .navbar ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}
.navbar-area .main-nav .navbar .navbar-nav {
  margin-right: 40px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item a {
  font-size: 14px;
  font-weight: 400;
  color: var(--whiteColor);
  text-transform: capitalize;
  padding-left: 15px;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0;
  margin-right: 20px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item a:hover, .navbar-area .main-nav .navbar .navbar-nav .nav-item a:focus, .navbar-area .main-nav .navbar .navbar-nav .nav-item a.active {
  color: #70a1ff;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item a i {
  font-size: 16px;
  position: relative;
  top: 2px;
  margin-left: 1px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item:hover a, .navbar-area .main-nav .navbar .navbar-nav .nav-item.active a {
  color: #70a1ff;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  background: var(--whiteColor);
  position: absolute;
  border: none;
  top: 80px;
  left: 0;
  width: 240px;
  z-index: 99;
  display: block;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  padding-top: 0;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu::before {
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  border-top: 0;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--whiteColor);
  border-left: 8px solid transparent;
  top: -8px;
  left: 20px;
  z-index: -11;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
  position: relative;
  overflow: hidden;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0px;
  width: 0;
  height: 100px;
  background: var(--mainColor);
  transition: 0.5s all ease;
  z-index: -1;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  text-transform: capitalize;
  padding: 10px 25px;
  margin: 0;
  color: #181818;
  border-bottom: 1px dashed #eeeeee;
  font-size: 15px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--mainColor);
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--mainColor);
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover a {
  color: var(--whiteColor);
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover::before {
  width: 100%;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child a {
  border-bottom: none;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item .dropdown-toggle::after {
  content: "+";
  margin-left: 0.355em;
  vertical-align: 0;
  border-top: none;
  border-right: none;
  border-bottom: 0;
  border-left: none;
  transition: 0.3s all;
}
.navbar-area .main-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 50px;
}
.navbar-area .box-btn::before, .navbar-area .box-btn::after {
  background: var(--whiteColor);
}
.navbar-area .box-btn:hover {
  color: #132460;
}
.navbar-area .nav-btn {
  margin-left: 20px;
  position: relative;
  top: 3px;
}
.navbar-area .nav-right {
  display: inline-flex;
  width: 100%;
  position: relative;
  top: -3px;
  max-width: 16%;
}
.navbar-area .nav-right ::placeholder {
  color: #f5faf5;
  opacity: 1;
}
.navbar-area .nav-right input.search {
  background: transparent;
  border: none;
  border-bottom: 1px solid #f5faff;
  position: relative;
  color: #f5faff;
  padding-left: 0;
  border-radius: 0;
}
.navbar-area .nav-right input.search:focus {
  box-shadow: none;
}
.navbar-area .nav-right button {
  background: none;
  border: none;
  color: #f5faff;
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 0;
  cursor: pointer;
  transition: var(--transition);
  z-index: 1;
}
.navbar-area .nav-right button:hover {
  color: var(--mainColor);
}

/*
Banner Area Css
======================================================*/
.banner-area {
  position: relative;
  background: url(assets/image/HomePage/bannerimg.jpg);
  background-size: cover;
  overflow: hidden;
  width: 100%;
  height: 623px;
}
.banner-area .banner-content {
  max-width: 635px;
  margin-left: auto;
  margin-top: 30px;
}
.banner-area .banner-content h1 {
  font-size: 55px;
  color: var(--whiteColor);
  margin: 0 0 30px;
}
.banner-area .banner-content p {
  color: var(--whiteColor);
  padding-right: 0%;
  font-family: "Open Sans";
  font-weight: 520;
  text-align: left;
}
.banner-area .banner-content .banner-btn {
  margin-top: 45px;
}
.banner-area .banner-content .banner-btn .box-btn {
  margin-right: 20px;
}
.banner-area .banner-content .banner-btn .box-btn::before, .banner-area .banner-content .banner-btn .box-btn::after {
  background: var(--whiteColor);
}
.banner-area .banner-content .banner-btn .box-btn:hover {
  color: #132460;
}
.banner-area .banner-img {
  animation: pulse 7s infinite;
}
.banner-area .home-shape .shape1 {
  position: absolute;
  top: 55%;
  left: 2%;
  animation: animationFramesOne 15s linear infinite;
}
.banner-area .home-shape .shape2 {
  position: absolute;
  top: 19%;
  left: 3%;
  animation: pulse 4s infinite;
}
.banner-area .home-shape .shape3 {
  position: absolute;
  bottom: 13%;
  left: 40%;
  animation: rotaed360 6s infinite linear;
}
.banner-area .home-shape .shape4 {
  position: absolute;
  top: 15%;
  left: 35%;
  animation: rotaed360 6s infinite linear;
}
.banner-area .home-shape .shape5 {
  position: absolute;
  top: 15%;
  left: 62%;
  animation: animationFramesOne 15s linear infinite;
}
.banner-area .home-shape .shape6 {
  position: absolute;
  bottom: 30%;
  right: 4%;
  animation: animationFramesOne 15s linear infinite;
}

/*
Slider 2 Area Css
======================================================*/
.slider-area-2 {
  position: relative;
  z-index: 1;
}
.slider-area-2 .home-slider .single-slider {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 90px;
  height: 100vh;
  position: relative;
  z-index: 1;
  border-bottom-right-radius: 90px;
}
.slider-area-2 .home-slider .single-slider::before {
  position: absolute;
  content: "";
  background: #000;
  opacity: 0.6;
  width: 100%;
  z-index: -1;
  height: 100%;
  top: 0;
  left: 0;
  border-bottom-left-radius: 90px;
  border-bottom-right-radius: 90px;
}
.slider-area-2 .home-slider .single-slider .slider-content {
  position: relative;
  max-width: 700px;
  margin: auto;
}
.slider-area-2 .home-slider .single-slider .slider-content h1 {
  font-size: 60px;
  color: var(--whiteColor);
  margin-bottom: 20px;
}
.slider-area-2 .home-slider .single-slider .slider-content p {
  color: var(--whiteColor);
  margin-bottom: 35px;
}
.slider-area-2 .home-slider .single-slider .slider-btn .box-btn {
  margin-right: 20px;
  border-radius: 50px;
}
.slider-area-2 .home-slider .single-slider .slider-btn .border-btn {
  border-radius: 50px;
}
.slider-area-2 .home-slider .single-slider .slider-btn .border-btn:hover {
  color: var(--mainColor);
}
.slider-area-2 .home-slider .single-slider .slider-btn .border-btn:hover::before {
  background-color: var(--whiteColor);
}
.slider-area-2 .home-slider .single-slider .slider-btn .border-btn:hover::after {
  background-color: var(--whiteColor);
}
.slider-area-2 .owl-item.active .slider-content.one h1 {
  animation: 1s 0.2s fadeInUpBig both;
}
.slider-area-2 .owl-item.active .slider-content.one p {
  animation: 1.5s 0.3s fadeInUpBig both;
}
.slider-area-2 .owl-item.active .slider-content.one a {
  animation: 2s 0.4s fadeInUpBig both;
}
.slider-area-2 .owl-nav .owl-next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  font-size: 16px;
  line-height: 50px;
  top: 50%;
  position: absolute;
  right: 1%;
  background: var(--mainColor);
  font-weight: bold;
  transition: 0.3s;
  color: var(--whiteColor);
  padding: 0 !important;
  transform: translateY(-50px);
}
.slider-area-2 .owl-nav .owl-next i {
  margin-left: 3px;
}
.slider-area-2 .owl-nav .owl-next:hover {
  background: var(--darkBlueColor);
  color: var(--whiteColor);
}
.slider-area-2 .owl-nav .owl-prev {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  font-size: 16px;
  line-height: 50px;
  left: 1%;
  padding: 0 !important;
  top: 50%;
  position: absolute;
  color: var(--whiteColor);
  font-weight: bold;
  background: var(--mainColor);
  transition: 0.3s;
  transform: translateY(-50px);
}
.slider-area-2 .owl-nav .owl-prev i {
  margin-right: 3px;
}
.slider-area-2 .owl-nav .owl-prev:hover {
  background: var(--darkBlueColor);
  color: var(--whiteColor);
}
.slider-area-2 .single-slider-bg-1 {
  background: url(assets/images/home-bg-3.jpg);
}
.slider-area-2 .single-slider-bg-2 {
  background: url(assets/images/home-bg-4.jpg);
}
.slider-area-2 .single-slider-bg-3 {
  background: url(assets/images/home-bg-5.jpg);
}

/*
Info Area Css
======================================================*/
.info-area {
  position: relative;
}
.info-area .single-info {
  position: relative;
  transition: 0.3s;
  z-index: 1;
  margin-bottom: 30px;
}
.info-area .single-info .info-img img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: var(--boxShadow);
}
.info-area .single-info .content {
  position: relative;
  max-width: 85%;
  margin: auto;
  background: var(--whiteColor);
  padding: 30px;
  text-align: center;
  margin-top: -40px;
  border-radius: 4px;
  transition: 0.3s;
  z-index: 1;
  box-shadow: var(--boxShadow);
}
.info-area .single-info .content h3 {
  margin-bottom: 0;
  color: var(--darkBlueColor);
  font-size: 20px;
}
.info-area .single-info .content h3 i {
  color: var(--mainColor);
  margin-right: 10px;
}
.info-area .single-info .content::before {
  position: absolute;
  content: "";
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: var(--darkBlueColor);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  z-index: -1;
}
.info-area .single-info .content .arrow {
  position: absolute;
  width: 35px;
  height: 35px;
  background: var(--mainColor);
  color: var(--whiteColor);
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  left: 50%;
  top: 110%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.3s;
}
.info-area .single-info .content .arrow a {
  color: var(--whiteColor);
}
.info-area .single-info .content .arrow i {
  position: relative;
  left: 1px;
  font-weight: normal;
}
.info-area .single-info:hover .content::before {
  bottom: 0;
  height: 100%;
}
.info-area .single-info:hover .content h3 {
  color: var(--whiteColor);
}
.info-area .single-info:hover .content h3 i {
  color: var(--whiteColor);
}
.info-area .single-info:hover .content .arrow {
  opacity: 1;
  top: 95%;
}

/*
Service Area Css
======================================================*/
.single-service1 {
  position: relative;
  text-align: center;
  padding: 23px;
  margin-bottom: 17px;
  height: 25rem;
  transition: var(--transition);
  border-radius: var(--borderRadius);
  z-index: 1;
  overflow: hidden;
  box-shadow: var(--boxShadow);
}
.single-service1 .service-img {
  margin-bottom: 20px;
}
.single-service1 .service-img img {
  width: 100%;
}
.single-service1 .service-content h3 {
  color: var(--darkBlueColor);
  margin-bottom: 5px;
}
.single-service1 .service-content p {
  margin-bottom: 10px;
}
.single-service1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: var(--darkBlueColor);
  z-index: -1;
  transition: all 0.5s;
}
.single-service1::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background: url(assets/images/service/hover.png) no-repeat;
  z-index: -1;
  transition: all 0.5s;
}
.single-service1:hover {
  transform: translateY(-10px);
}
.single-service1:hover h3 {
  color: var(--whiteColor);
}
.single-service1:hover p {
  color: #e9e9e9;
}
.single-service1:hover::before {
  left: 0;
}
.single-service1:hover::after {
  right: 0;
}
.single-service1:hover .service-content .line-bnt {
  color: var(--whiteColor);
}

.single-service {
  position: relative;
  text-align: center;
  padding: 23px;
  margin-bottom: 17px;
  height: 26rem;
  transition: var(--transition);
  border-radius: var(--borderRadius);
  z-index: 1;
  overflow: hidden;
  box-shadow: var(--boxShadow);
}
.single-service .service-img {
  margin-bottom: 20px;
}
.single-service .service-img img {
  width: 100%;
}
.single-service .service-content h3 {
  color: var(--darkBlueColor);
  margin-bottom: 5px;
}
.single-service .service-content p {
  margin-bottom: 10px;
  height: 11.5rem;
  text-align: center;
}
.single-service::before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: var(--darkBlueColor);
  z-index: -1;
  transition: all 0.5s;
}
.single-service::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background: url(assets/images/service/hover.png) no-repeat;
  z-index: -1;
  transition: all 0.5s;
}
.single-service:hover {
  transform: translateY(-10px);
}
.single-service:hover h3 {
  color: var(--whiteColor);
}
.single-service:hover p {
  color: #e9e9e9;
}
.single-service:hover::before {
  left: 0;
}
.single-service:hover::after {
  right: 0;
}
.single-service:hover .service-content .line-bnt {
  color: var(--whiteColor);
}

/*
Home Company Area Css
======================================================*/
.home-company-area {
  padding-top: 30px;
  padding-bottom: 30px;
}
.home-company-area .company-content {
  margin-top: 5px;
  margin-bottom: 66px;
  text-align: justify;
  padding-right: 50px;
}
.home-company-area .company-tittle {
  position: relative;
  text-align: start;
}
.home-company-area .company-tittle span {
  color: var(--mainColor);
  font-size: 14px;
  margin-bottom: 8px;
  display: inline-block;
}
.home-company-area .company-tittle h2 {
  margin-bottom: 20px;
  font-size: 40px;
}
.home-company-area .box-btn {
  margin-top: 35px;
}

.company-img {
  position: relative;
  z-index: 1;
}
.company-img::before {
  position: absolute;
  content: "";
  width: 350px;
  height: 100px;
  background: #e6335a;
  z-index: -1;
  left: -30px;
  top: -30px;
  border-radius: 4px;
}
.company-img::after {
  position: absolute;
  content: "";
  width: 350px;
  height: 100px;
  background: var(--darkBlueColor);
  z-index: -1;
  right: -30px;
  bottom: -30px;
  border-radius: 4px;
}

/*
Choose Area Css
======================================================*/
.choose-area .choose-content .faq-accordion .faq-item {
  position: relative;
  margin-bottom: 20px;
  box-shadow: var(--boxShadow);
}
.choose-area .choose-content .faq-accordion .faq-item .faq-header {
  background-color: var(--whiteColor);
  box-shadow: var(--boxShadow);
  padding: 20px 60px 20px 25px;
  color: var(--darkBlueColor);
  position: relative;
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  display: block;
  cursor: pointer;
}
.choose-area .choose-content .faq-accordion .faq-item .faq-header i {
  top: 50%;
  right: 20px;
  content: "\eb89";
  position: absolute;
  transform: translateY(-50%);
}
.choose-area .choose-content .faq-accordion .faq-item .faq-content {
  background-color: var(--whiteColor);
  padding: 15px 30px 15px 30px;
  font-size: 16px;
}
.choose-area .choose-content .faq-accordion .faq-item .faq-content p {
  color: var(--darkBlueColor);
}
.choose-area .choose-content .faq-accordion .faq-item:last-child {
  margin-bottom: 0;
}

/*
Choose Area 2 Css
======================================================*/
.home2-choose-area .single-choose {
  position: relative;
  transition: 0.3s;
  overflow: hidden;
  text-align: center;
  box-shadow: var(--boxShadow);
  margin-bottom: 30px;
}
.home2-choose-area .single-choose .icon {
  background: var(--whiteColor);
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
  margin: auto;
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
  padding: 10px 20px;
}
.home2-choose-area .single-choose .icon i {
  font-size: 80px;
  color: var(--darkBlueColor);
  transition: 0.3s;
}
.home2-choose-area .single-choose h3 {
  text-align: center;
  color: var(--headingColor);
  margin-bottom: 0;
  padding: 10px 5px;
  font-size: 17px;
  background-color: #f1f1f1;
}
.home2-choose-area .single-choose:hover {
  transform: translateY(-10px);
}
.home2-choose-area .single-choose:hover .icon i {
  color: var(--mainColor);
}
.home2-choose-area .choose-c {
  position: relative;
  margin-top: 75px;
}
.home2-choose-area .choose-c .content {
  max-width: 600px;
  margin-left: 30px;
}
.home2-choose-area .choose-c .content h3 {
  font-size: 30px;
  color: var(--headingColor);
  margin-bottom: 15px;
  font-weight: 600;
}
.home2-choose-area .choose-c .content p {
  color: var(--headingColor);
  margin-bottom: 10px;
}
.home2-choose-area .choose-c .content p:last-child {
  margin-bottom: 0;
}
.home2-choose-area .choose-c .content .box-btn {
  border-radius: 50px;
  margin-top: 15px;
}

/*
Process Area Css
======================================================*/
.home-process-area {
  background-color: var(--darkBlueColor);
}
.home-process-area .section-title span {
  color: var(--whiteColor);
}
.home-process-area .section-title h2 {
  color: var(--whiteColor);
}
.home-process-area .section-title p {
  color: var(--whiteColor);
  text-align: center;
}
.home-process-area .single-process {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}
.home-process-area .single-process .icon {
  background: #1e2a55;
  display: inline-block;
  width: 71px;
  height: 71px;
  line-height: 60px;
  margin-bottom: 10px;
  border-radius: 3px;
  position: relative;
}
.home-process-area .single-process .content h3 {
  color: var(--whiteColor);
}
.home-process-area .single-process .content p {
  color: var(--whiteColor);
  margin-bottom: 0;
  font-size: 14px;
}
.home-process-area .single-process span {
  position: absolute;
  z-index: 1;
}
.home-process-area .single-process span::before {
  content: "";
  position: absolute;
  content: "";
  height: 134px;
  border-right: 1px dashed #f5faff;
  transform: rotate(90deg);
  top: -35px;
  left: 82.3px;
  z-index: -1;
}
.home-process-area .single-process span img {
  max-width: 100px;
  margin-left: 65px;
  animation: leftani 5s infinite linear;
}
@keyframes leftani {
  0% {
    transform: translateX(-15px);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(-15px);
  }
}

/*
Home Case Area Css
======================================================*/
.home-case .case {
  position: relative;
}
.home-case .case .nav-tabset {
  border-bottom: 1px solid var(--bodyColor);
  padding-bottom: 5px;
  margin-bottom: 40px;
  text-align: center;
}
.home-case .case .nav-tabset .nav-tab {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
}
.home-case .case .nav-tabset .nav-tab span {
  color: #50607f;
}
.home-case .case .nav-tabset .nav-tab.active {
  position: relative;
}
.home-case .case .nav-tabset .nav-tab.active::before {
  position: absolute;
  width: 100%;
  height: 5px;
  background: var(--mainColor);
  content: "";
  bottom: -14px;
}
.home-case .case .nav-tabset .nav-tab.active span {
  color: var(--mainColor);
}
.home-case .case-list .single-case {
  margin-bottom: 30px;
  transition: var(--transition);
}
.home-case .case-list .single-case .case-img img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.home-case .case-list .single-case .content {
  background: var(--whiteColor);
  box-shadow: var(--boxShadow);
  padding: 30px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.home-case .case-list .single-case .content h3 {
  margin-bottom: 10px;
  transition: var(--transition);
}
.home-case .case-list .single-case .content p {
  margin-bottom: 12px;
}
.home-case .case-list .single-case:hover {
  transform: translateY(-3px);
}
.home-case .case-list .single-case:hover h3 {
  color: var(--mainColor);
}

/*
Feature Area Css
======================================================*/
.feature-area .feature-tittle {
  margin-bottom: 20px;
}
.feature-area .feature-tittle span {
  color: var(--mainColor);
  display: inline-block;
  margin-top: -8px;
  margin-bottom: 8px;
}
.feature-area .feature-tittle h2 {
  font-size: 40px;
  margin-bottom: 15px;
}
.feature-area .contnet ul {
  padding: 0;
  margin: 0;
}
.feature-area .contnet ul li {
  position: relative;
  padding-left: 30px;
  color: var(--darkBlueColor);
  font-size: 18px;
  margin-bottom: 10px;
}
.feature-area .contnet ul li:last-child {
  margin-bottom: 0;
}
.feature-area .contnet ul li i {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  color: var(--mainColor);
  font-size: 18px;
}
.feature-area .contnet .box-btn {
  margin-top: 30px;
}
.feature-area .feature-img {
  animation: updown 5s infinite linear;
}
@keyframes updown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

/*
Team Area Css
======================================================*/
.home-team-area .single-team {
  position: relative;
  margin-bottom: 30px;
  transition: 0.3s;
}
.home-team-area .single-team .team-img {
  position: relative;
  overflow: hidden;
}
.home-team-area .single-team .team-img .social {
  position: absolute;
  text-align: center;
  margin: 0;
  padding: 0;
  z-index: 3;
  bottom: -200px;
  transition: 0.5s;
  position: absolute;
  width: 100%;
  left: 0;
}
.home-team-area .single-team .team-img .social li {
  display: inline-block;
  margin: 0 5px;
}
.home-team-area .single-team .team-img .social li a {
  width: 35px;
  height: 35px;
  background: var(--whiteColor);
  border-radius: 50%;
  line-height: 37px;
  text-align: center;
  transition: 0.3s;
  color: var(--mainColor);
  transition: 0.3s;
}
.home-team-area .single-team .team-img .social li a i {
  font-size: 17px;
}
.home-team-area .single-team .team-img .social li a:hover {
  background: var(--mainColor);
  color: var(--whiteColor);
}
.home-team-area .single-team .content {
  padding: 15px;
  background-color: #f5faff;
  position: relative;
  z-index: 2;
}
.home-team-area .single-team .content h3 {
  color: var(--darkBlueColor);
  margin-bottom: 0;
  transition: var(--transition);
}
.home-team-area .single-team .content p {
  margin-bottom: 0;
  font-size: 14px;
  transition: var(--transition);
}
.home-team-area .single-team .content::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  bottom: -20px;
  background: var(--darkBlueColor);
  opacity: 0.95;
  transition: 0.5s;
  z-index: -1;
  bottom: 0;
  left: 0;
}
.home-team-area .single-team:hover .content::before {
  height: 120px;
}
.home-team-area .single-team:hover .social {
  bottom: 8px;
}
.home-team-area .single-team:hover h3,
.home-team-area .single-team:hover p {
  color: var(--whiteColor);
}
.home-team-area .owl-dots {
  position: relative;
  margin-top: 20px;
  text-align: center;
  line-height: 1;
}
.home-team-area .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background: #d0d0d0 !important;
  border-radius: 50%;
  margin: 0 5px;
}
.home-team-area .owl-dots .owl-dot.active span {
  background: var(--mainColor) !important;
}

/*
Client Area Css
======================================================*/
.client-area.c-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
}
.client-area .top-title {
  color: var(--headingColor);
}
.client-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColor);
  z-index: -1;
  opacity: 0.8;
}
.client-area .owl-carousel .owl-item img {
  display: block;
  width: auto;
}

.single-client {
  background-color: var(--whiteColor);
  padding: 30px;
  position: relative;
  text-align: center;
  margin-top: 30px;
  height: 17rem;
}
.single-client img {
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: auto;
}
.single-client p {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
}
.single-client h3 {
  margin-bottom: 0;
}

/*
Blog Area Css
======================================================*/
.home-blog-area {
  position: relative;
}
.home-blog-area .single-blog {
  background: var(--whiteColor);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  transition: 0.5s;
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
  z-index: 1;
}
.home-blog-area .single-blog .content {
  padding: 30px;
}
.home-blog-area .single-blog .content ul {
  padding: 0;
  margin: 0;
}
.home-blog-area .single-blog .content ul li {
  font-size: 13px;
  display: inline-block;
}
.home-blog-area .single-blog .content ul li a {
  position: relative;
  margin-left: 25px;
}
.home-blog-area .single-blog .content ul li a::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  left: -16px;
  top: 3px;
  background: var(--bodyColor);
}
.home-blog-area .single-blog .content span {
  color: var(--headingColor);
  font-weight: 500;
}
.home-blog-area .single-blog .content h3 {
  margin-top: 10px;
  transition: var(--transition);
  margin-bottom: 0;
}
.home-blog-area .single-blog .content p {
  margin-top: 10px;
  transition: var(--transition);
}
.home-blog-area .single-blog .content .line-bnt {
  color: var(--darkBlueColor);
}
.home-blog-area .single-blog::after {
  position: absolute;
  content: "";
  background: var(--darkBlueColor);
  opacity: 0.8;
  bottom: 0;
  left: 100%;
  right: 0;
  width: 0;
  transition: 0.5s;
  z-index: -1;
  height: 100%;
}
.home-blog-area .single-blog:hover::before {
  width: 100%;
  left: 0;
}
.home-blog-area .single-blog:hover::after {
  width: 100%;
  left: 0;
}
.home-blog-area .single-blog:hover span,
.home-blog-area .single-blog:hover h3 {
  color: var(--whiteColor);
}
.home-blog-area .single-blog:hover p {
  color: var(--whiteColor);
}
.home-blog-area .single-blog:hover ul li {
  color: var(--whiteColor);
}
.home-blog-area .single-blog:hover ul li a {
  color: var(--whiteColor);
}
.home-blog-area .single-blog:hover .line-bnt {
  color: var(--whiteColor);
}
.home-blog-area .blog-btn {
  margin-top: 20px;
}
.home-blog-area .blog-btn p {
  font-size: 17px;
  color: var(--headingColor);
  margin-bottom: 0;
}
.home-blog-area .blog-btn p a {
  color: var(--mainColor);
  text-decoration: underline;
}

/*
Technology Area Css
======================================================*/
.technology-area .technology-content h2 {
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: -20px;
}
.technology-area .choose-img {
  position: relative;
}
.technology-area .choose-img .technology-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.video-btn {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  color: var(--mainColor);
  position: relative;
  top: 3px;
  z-index: 1;
  background-color: var(--mainColor);
}
.video-btn i {
  font-size: 40px;
  font-weight: 700;
  color: var(--whiteColor);
  position: absolute;
  top: 0;
  left: 3px;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
}
.video-btn::after, .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--mainColor);
}
.video-btn::before {
  animation: ripple 1.6s ease-out infinite;
}
.video-btn::after {
  animation: ripple 1.6s ease-out infinite;
}
.video-btn:hover {
  background-color: var(--mainColor);
}
.video-btn:hover i {
  color: var(--whiteColor);
}

@keyframes ripple {
  0%, 35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
/*
About Area Css
======================================================*/
.about-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.about-area .section-tittle {
  max-width: 850px;
}
.about-area img {
  border-radius: 10px;
}
.about-area .about-img {
  position: relative;
  margin-top: 40px;
}
.about-area .about-img .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.about-area .about-img .video-btn .video-pop {
  margin-top: 26px;
  font-size: 16px;
  color: var(--mainColor);
}
.about-area .about-img .video-btn .video-pop .video {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  position: relative;
  text-align: center;
  border-radius: 50%;
  color: var(--whiteColor);
  background: var(--mainColor);
  box-shadow: 0 0 20px 8px rgba(228, 221, 221, 0.363);
  transition: 0.5s;
  margin: 0 10px 0 10px;
}
.about-area .about-img .video-btn .video-pop .video i {
  font-size: 40px;
  position: relative;
  top: 9px;
  left: 3px;
}
.about-area .about-img .video-btn .video-pop .video::before, .about-area .about-img .video-btn .video-pop .video::after {
  animation: animatin 1.2s ease-out infinite;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 10px solid var(--mainColor);
}
.about-area .about-img .video-btn .video-pop .video::before {
  animation: animatin 1.5s linear infinite;
}
.about-area .about-img .video-btn .video-pop .video::after {
  animation: animatin 2s linear 1s infinite;
}
@keyframes animatin {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.about-area .content {
  margin-top: 50px;
}
.about-area .content .about-content p {
  color: #50607f;
}
.about-area .content .about-content p:last-child {
  margin-bottom: 0;
}

/*
Solutions Area Css
======================================================*/
.solution-img {
  margin-bottom: 30px;
}

.solutions-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.solutions-area .section-tittle {
  max-width: 850px;
}
.solutions-area .single-solutions {
  position: relative;
  text-align: center;
  padding: 30px 20px;
  background: var(--whiteColor);
  margin-top: 30px;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  transition: all 0.5s;
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
}
.solutions-area .single-solutions .solutions-img {
  margin-bottom: 20px;
}
.solutions-area .single-solutions .solutions-content h3 {
  color: var(--darkBlueColor);
  margin-bottom: 10px;
}
.solutions-area .single-solutions .solutions-content p {
  color: #50607f;
  margin-bottom: 10px;
}
.solutions-area .single-solutions .solutions-content .line-bnt {
  color: var(--mainColor);
  font-size: 14px;
}
.solutions-area .single-solutions::before {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: var(--darkBlueColor);
  z-index: -1;
  transition: all 0.5s;
}
.solutions-area .single-solutions::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background: url(assets/images/service/hover-img.png);
  z-index: -2;
  transition: all 0.5s;
}
.solutions-area .single-solutions .solutions-back {
  position: absolute;
  transform: translateY(40px);
  padding-right: 20px;
}
.solutions-area .single-solutions:hover {
  transform: translateY(-10px);
}
.solutions-area .single-solutions:hover h3 {
  color: var(--whiteColor);
  transform: translateY(-50px);
  opacity: 1;
}
.solutions-area .single-solutions:hover p {
  color: #e9e9e9;
  opacity: 1;
  transform: translateY(-35px);
}
.solutions-area .single-solutions:hover .line-bnt {
  color: #e6335a;
  text-decoration: underline;
  opacity: 1;
  transform: translateY(-25px);
}
.solutions-area .single-solutions:hover .solutions-img {
  opacity: 0;
}
.solutions-area .single-solutions:hover::before {
  left: 0;
}
.solutions-area .single-solutions:hover::after {
  right: 0;
}

/*
Facilities Area Css
======================================================*/
.facilities-area {
  padding-top: 60px;
  border-top: 1px solid rgba(238, 238, 238, 0.9333333333);
  position: relative;
}
.facilities-area .mt-40 {
  margin-top: 40px;
}
.facilities-area .section-tittle {
  max-width: 850px;
}
.facilities-area .section-tittle p {
  color: #181818;
}
.facilities-area .content h3 {
  color: var(--headingColor);
  margin-bottom: 10px;
}
.facilities-area .content p {
  font-size: 16px;
  line-height: 1.9;
  color: #181818;
}
.facilities-area .content .box-btn {
  margin-top: 10px;
  padding: 15px 40px;
  border-radius: 50px;
}

/*
Solutions Details Area Css
======================================================*/
.solutions-details-area {
  position: relative;
}
.solutions-details-area .single-solutions {
  position: relative;
}
.solutions-details-area .single-solutions img {
  border-radius: 10px;
}
.solutions-details-area .single-solutions .content {
  margin-bottom: 25px;
}
.solutions-details-area .single-solutions .content h2 {
  font-size: 25px;
  color: #121e4c;
  margin: 15px 0 15px;
}
.solutions-details-area .single-solutions .content p {
  color: #181818;
  padding-right: 10px;
}
.solutions-details-area .single-solutions .social {
  position: absolute;
  text-align: center;
  margin: 0;
  padding: 0;
  z-index: 3;
  position: relative;
  display: inline-block;
}
.solutions-details-area .single-solutions .social p {
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
  margin-bottom: 0;
}
.solutions-details-area .single-solutions .social li {
  display: inline-block;
}
.solutions-details-area .single-solutions .social li a {
  width: 35px;
  height: 35px;
  background: #121e4c;
  border-radius: 50%;
  line-height: 39px;
  text-align: center;
  transition: 0.3s;
  color: var(--whiteColor);
  transition: 0.3s;
}
.solutions-details-area .single-solutions .social li a i {
  font-size: 17px;
}
.solutions-details-area .single-solutions .social li a:hover {
  background: var(--mainColor);
  color: var(--whiteColor);
  transform: translateY(-10px);
}
.solutions-details-area .right-content {
  position: relative;
}
.solutions-details-area .right-content .serarch {
  position: relative;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background: var(--whiteColor);
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.solutions-details-area .right-content .serarch h3 {
  padding: 15px 20px;
  color: var(--whiteColor);
  background: #121e4c;
  margin-bottom: 20px;
}
.solutions-details-area .right-content .serarch .search-form {
  position: relative;
  max-width: 90%;
  margin: auto;
}
.solutions-details-area .right-content .serarch .search-form input#search {
  height: 50px;
}
.solutions-details-area .right-content .serarch .search-form input#search:focus {
  box-shadow: none;
}
.solutions-details-area .right-content .serarch .search-form button.box-btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  height: 100%;
  padding: 0 17px;
  font-size: 20px;
}
.solutions-details-area .right-content .recent-post {
  position: relative;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background: var(--whiteColor);
}
.solutions-details-area .right-content .recent-post h3 {
  padding: 15px 20px;
  color: var(--whiteColor);
  background: #121e4c;
  margin-bottom: 20px;
}
.solutions-details-area .right-content .recent-post .post {
  padding: 20px;
}
.solutions-details-area .right-content .recent-post .post .item {
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #efefef;
}
.solutions-details-area .right-content .recent-post .post .item a {
  float: left;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}
.solutions-details-area .right-content .recent-post .post :last-child {
  border-bottom: none;
}
.solutions-details-area .right-content .recent-post .post .info {
  overflow: hidden;
  margin-top: 20px;
}
.solutions-details-area .right-content .recent-post .post .info time {
  font-size: 13px;
  color: #929292;
  display: block;
  margin-bottom: 4px;
}
.solutions-details-area .right-content .recent-post .post .info h4 a {
  color: var(--darkBlueColor);
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
}
.solutions-details-area .right-content .recent-post .post .info h4 a:hover {
  transform: translateX(10px);
}
.solutions-details-area .right-content .features {
  position: relative;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background: var(--whiteColor);
}
.solutions-details-area .right-content .features h3 {
  padding: 15px 20px;
  color: var(--whiteColor);
  background: #121e4c;
  margin-bottom: 0;
}
.solutions-details-area .right-content .features .feature-list {
  margin: 0;
  padding: 30px;
}
.solutions-details-area .right-content .features .feature-list li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #181818;
  padding-left: 23px;
  font-size: 16px;
  border-bottom: 1px dashed #efefef;
  font-weight: 500;
}
.solutions-details-area .right-content .features .feature-list li::before {
  background: #121e4c;
  position: absolute;
  height: 8px;
  width: 8px;
  content: "";
  left: 0;
  top: 9px;
  transform: rotate(45deg);
  animation: rotaed360 4s infinite linear;
}

/*
Case Area Css
======================================================*/
.case-area {
  position: relative;
}
.case-area .case {
  position: relative;
}
.case-area .case .nav-tabset {
  border-bottom: 1px solid #7289b8;
  text-align: center;
}
.case-area .case .all-case {
  border-bottom: 1px solid #7289b8;
}
.case-area .case .all-case li {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 6px;
}
.case-area .case .all-case li span {
  color: #50607f;
}
.case-area .case .all-case li:last-child {
  margin-right: 0;
}
.case-area .case .all-case .active {
  position: relative;
}
.case-area .case .all-case .active::before {
  position: absolute;
  width: 100%;
  height: 5px;
  background: var(--mainColor);
  content: "";
  bottom: -9px;
}
.case-area .case .all-case .active span {
  color: var(--mainColor);
}
.case-area .case-list {
  position: relative;
}
.case-area .case-list .single-case {
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  transition: 0.5s;
  z-index: 1;
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
}
.case-area .case-list .single-case .case-img img {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.case-area .case-list .single-case .content {
  background: var(--whiteColor);
  padding: 30px 20px;
}
.case-area .case-list .single-case .content a h3 {
  color: var(--darkBlueColor);
  transition: 0.5s;
}
.case-area .case-list .single-case .content .line-bnt {
  margin-top: 10px;
  z-index: 1;
  position: relative;
}
.case-area .case-list .single-case .content p {
  bottom: -200px;
  position: absolute;
  transition: 0.5s;
  color: #f5faff;
  padding-right: 14px;
}
.case-area .case-list .single-case::before {
  position: absolute;
  bottom: 0;
  content: "";
  background: var(--darkBlueColor);
  overflow: hidden;
  width: 100%;
  height: 0;
  opacity: 0.95;
  transition: 0.5s;
}
.case-area .case-list .single-case:hover P {
  bottom: 65px;
}
.case-area .case-list .single-case:hover::before {
  height: 65%;
}
.case-area .case-list .single-case:hover a h3 {
  transform: translateY(-110px);
  color: #f5ebff;
}
.case-area .case-list .single-case:hover .line-bnt {
  color: #e6335a;
  text-decoration: underline;
  transform: translateY(-10px);
}
.case-area .dropdown {
  margin-top: 30px;
}
.case-area .dropdown p {
  color: var(--headingColor);
  margin-bottom: 5px;
}
.case-area .dropdown button {
  border: 1px solid var(--headingColor);
  background: transparent;
  padding: 8px 30px;
  color: var(--headingColor);
  font-weight: 500;
}
.case-area .dropdown .dropdown-item {
  cursor: pointer;
}

/*
Case Details Area Css
======================================================*/
.case-details-area {
  position: relative;
}
.case-details-area .single-case {
  position: relative;
}
.case-details-area .single-case img {
  border-radius: 10px;
}
.case-details-area .single-case .case-img {
  margin-bottom: 25px;
}
.case-details-area .single-case .content {
  margin-bottom: 25px;
}
.case-details-area .single-case .content h2 {
  font-size: 25px;
  color: #121e4c;
  margin: 15px 0 10px;
}
.case-details-area .single-case .content p {
  color: #181818;
  padding-right: 10px;
  margin-bottom: 10px;
}
.case-details-area .single-case .social {
  position: absolute;
  text-align: center;
  margin: 0;
  padding: 0;
  z-index: 3;
  position: relative;
  display: inline-block;
}
.case-details-area .single-case .social p {
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0;
}
.case-details-area .single-case .social li {
  display: inline-block;
}
.case-details-area .single-case .social li a {
  width: 35px;
  height: 35px;
  background: #121e4c;
  border-radius: 50%;
  line-height: 37px;
  text-align: center;
  transition: 0.3s;
  color: var(--whiteColor);
  transition: 0.3s;
}
.case-details-area .single-case .social li a i {
  font-size: 17px;
}
.case-details-area .single-case .social li a:hover {
  background: var(--mainColor);
  color: var(--whiteColor);
  transform: translateY(-10px);
}
.case-details-area .right-content {
  position: relative;
}
.case-details-area .right-content .serarch {
  position: relative;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background: var(--whiteColor);
  padding-bottom: 20px;
  margin-bottom: 30px;
}
.case-details-area .right-content .serarch h3 {
  padding: 15px 20px;
  color: var(--whiteColor);
  background: #121e4c;
  margin-bottom: 20px;
}
.case-details-area .right-content .serarch .search-form {
  position: relative;
  max-width: 90%;
  margin: auto;
}
.case-details-area .right-content .serarch .search-form input#search {
  height: 50px;
}
.case-details-area .right-content .serarch .search-form input#search:focus {
  box-shadow: none;
}
.case-details-area .right-content .serarch .search-form button.box-btn {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  height: 100%;
  padding: 0 17px;
  font-size: 20px;
}
.case-details-area .right-content .recent-post {
  position: relative;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background: var(--whiteColor);
}
.case-details-area .right-content .recent-post h3 {
  padding: 15px 20px;
  color: var(--whiteColor);
  background: #121e4c;
  margin-bottom: 20px;
}
.case-details-area .right-content .recent-post .post {
  padding: 20px;
}
.case-details-area .right-content .recent-post .post .item {
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #efefef;
}
.case-details-area .right-content .recent-post .post .item a {
  float: left;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}
.case-details-area .right-content .recent-post .post :last-child {
  border-bottom: none;
}
.case-details-area .right-content .recent-post .post .info {
  overflow: hidden;
  margin-top: 20px;
}
.case-details-area .right-content .recent-post .post .info time {
  font-size: 13px;
  color: #929292;
  display: block;
  margin-bottom: 4px;
}
.case-details-area .right-content .recent-post .post .info h4 a {
  color: var(--darkBlueColor);
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
}
.case-details-area .right-content .recent-post .post .info h4 a:hover {
  transform: translateX(10px);
}
.case-details-area .right-content .features {
  margin-top: 30px;
  position: relative;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background: var(--whiteColor);
}
.case-details-area .right-content .features h3 {
  padding: 15px 20px;
  color: var(--whiteColor);
  background: #121e4c;
  margin-bottom: 0;
}
.case-details-area .right-content .features .feature-list {
  margin: 0;
  padding: 30px;
}
.case-details-area .right-content .features .feature-list li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #181818;
  padding-left: 23px;
  font-size: 16px;
  border-bottom: 1px dashed #efefef;
  font-weight: 500;
}
.case-details-area .right-content .features .feature-list li::before {
  background: #121e4c;
  position: absolute;
  height: 8px;
  width: 8px;
  content: "";
  left: 0;
  top: 9px;
  transform: rotate(45deg);
  animation: rotaed360 4s infinite linear;
}

/*
Blog Area Css
======================================================*/
.blog-area {
  position: relative;
  transition: 0.3s;
  overflow: hidden;
}
.blog-area .single-blog {
  background: var(--whiteColor);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: relative;
  margin-top: 30px;
  overflow: hidden;
  transition: 0.5s;
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
  z-index: 1;
}
.blog-area .single-blog .content {
  padding: 30px;
}
.blog-area .single-blog .content ul {
  padding: 0;
  margin: 0;
}
.blog-area .single-blog .content ul li {
  font-size: 13px;
  display: inline-block;
}
.blog-area .single-blog .content ul li a {
  position: relative;
  margin-left: 25px;
}
.blog-area .single-blog .content ul li a::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 15px;
  left: -16px;
  top: 2px;
  background: var(--darkBlueColor);
}
.blog-area .single-blog .content span {
  color: var(--headingColor);
  font-weight: 500;
}
.blog-area .single-blog .content h3 {
  color: var(--darkBlueColor);
  margin-top: 10px;
}
.blog-area .single-blog .content p {
  margin-top: 10px;
  color: #50607f;
}
.blog-area .single-blog .content .line-bnt {
  color: var(--darkBlueColor);
}
.blog-area .single-blog::after {
  position: absolute;
  content: "";
  background: var(--darkBlueColor);
  opacity: 0.8;
  bottom: 0;
  left: 100%;
  right: 0;
  width: 0;
  transition: 0.5s;
  z-index: -1;
  height: 100%;
}
.blog-area .single-blog:hover::before {
  width: 100%;
  left: 0;
}
.blog-area .single-blog:hover::after {
  width: 100%;
  left: 0;
}
.blog-area .single-blog:hover span,
.blog-area .single-blog:hover h3 {
  color: #f5ebff;
}
.blog-area .single-blog:hover p {
  color: var(--whiteColor);
}
.blog-area .single-blog:hover ul li {
  color: #cbcbcb;
}

/*
Blog Details Area Style
=====================================================*/
.blog-details-area {
  /*
  Sidebar Widget Area Style
  ================================*/
}
.blog-details-area .blog-details-desc .article-image {
  position: relative;
  background-color: var(--headingColor);
}
.blog-details-area .blog-details-desc .article-content {
  margin-top: 30px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta {
  margin-bottom: -10px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li {
  position: relative;
  display: inline-block;
  color: var(--mainColor);
  margin-right: 21px;
  font-size: 14px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li span {
  display: inline-block;
  color: var(--mainColor);
  font-weight: 500;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li a {
  display: inline-block;
  color: #666666;
  font-size: 13px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li a:hover {
  color: var(--mainColor);
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li i {
  color: var(--mainColor);
  margin-right: 2px;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: var(--mainColor);
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li:last-child {
  margin-right: 0;
}
.blog-details-area .blog-details-desc .article-content .entry-meta ul li:last-child::before {
  display: none;
}
.blog-details-area .blog-details-desc .article-content h3 {
  margin-bottom: 13px;
  margin-top: 25px;
  font-size: 30px;
}
.blog-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.blog-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 li {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-right: 10px;
  padding-left: 10px;
}
.blog-details-area .blog-details-desc .article-content .wp-block-gallery.columns-3 li figure {
  margin-bottom: 0;
}
.blog-details-area .blog-details-desc .article-footer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.blog-details-area .blog-details-desc .article-footer .article-tags {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-area .blog-details-desc .article-footer .article-tags span {
  display: inline-block;
  color: var(--mainColor);
  font-size: 20px;
  margin-right: 5px;
  position: relative;
  top: 4px;
}
.blog-details-area .blog-details-desc .article-footer .article-tags a {
  display: inline-block;
  color: #666666;
  font-weight: 600;
}
.blog-details-area .blog-details-desc .article-footer .article-tags a:hover {
  color: var(--mainColor);
}
.blog-details-area .blog-details-desc .article-footer .article-share {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social {
  padding-left: 0;
  list-style-type: none;
  text-align: right;
  margin-bottom: 0;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li {
  margin-left: 3px;
  display: inline-block;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li span {
  display: inline-block;
  margin-right: 2px;
  font-weight: 500;
  color: #666666;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li a {
  display: block;
  color: var(--whiteColor);
  width: 32px;
  height: 32px;
  line-height: 33px;
  border-radius: 50%;
  background-color: var(--mainColor);
  text-align: center;
  font-size: 14px;
}
.blog-details-area .blog-details-desc .article-footer .article-share .social li a:hover {
  animation: rubberBand 1s linear;
}
.blog-details-area .blog-details-desc .post-navigation {
  margin-top: 30px;
}
.blog-details-area blockquote {
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 50px !important;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: var(--borderRadius);
}
.blog-details-area blockquote p {
  color: var(--headingColor);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 17px !important;
  text-align: center;
}
.blog-details-area blockquote cite {
  display: none;
}
.blog-details-area blockquote::before {
  color: var(--mainColor);
  content: "\ee34";
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: "boxicons";
  font-size: 140px;
  font-weight: 900;
  opacity: 0.5;
}
.blog-details-area blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--mainColor);
  margin-top: 20px;
  margin-bottom: 20px;
}
.blog-details-area .post-navigation {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
  padding-bottom: 20px;
}
.blog-details-area .post-navigation .navigation-links {
  display: flex;
  flex-wrap: wrap;
}
.blog-details-area .post-navigation .navigation-links .nav-previous {
  flex: 0 0 50%;
  max-width: 50%;
}
.blog-details-area .post-navigation .navigation-links .nav-previous a i {
  margin-right: 0;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.blog-details-area .post-navigation .navigation-links .nav-next {
  flex: 0 0 50%;
  max-width: 50%;
  text-align: right;
}
.blog-details-area .post-navigation .navigation-links .nav-next a i {
  margin-left: 0;
  font-size: 20px;
  position: relative;
  top: 4px;
}
.blog-details-area .post-navigation .navigation-links div a {
  display: inline-block;
  font-weight: 600;
  color: var(--headingColor);
}
.blog-details-area .post-navigation .navigation-links div a:hover {
  color: var(--mainColor);
}
.blog-details-area .comments-area {
  padding: 25px;
  margin-top: 30px;
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
  background-color: var(--whiteColor);
}
.blog-details-area .comments-area .comments-title {
  position: relative;
  margin-bottom: 30px;
  line-height: initial;
  font-size: 22px;
}
.blog-details-area .comments-area .comments-title::before {
  content: "";
  height: 25px;
  width: 3px;
  left: -25px;
  position: absolute;
  background: var(--mainColor);
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-area .comments-area ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .comments-area ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .comments-area .children {
  margin-left: 40px;
}
.blog-details-area .comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.blog-details-area .comments-area .comment-body .reply {
  margin-top: 15px;
}
.blog-details-area .comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: var(--headingColor);
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-size: 13px;
  font-weight: 600;
}
.blog-details-area .comments-area .comment-body .reply a:hover {
  color: var(--whiteColor);
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-meta {
  margin-bottom: 0.8em;
}
.blog-details-area .comments-area .comment-author {
  font-size: 16px;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}
.blog-details-area .comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
  border-radius: 50%;
}
.blog-details-area .comments-area .comment-author .fn {
  font-weight: 600;
  color: var(--headingColor);
  font-size: 18px;
}
.blog-details-area .comments-area .comment-author .says {
  display: none;
}
.blog-details-area .comments-area .comment-metadata {
  color: #666666;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}
.blog-details-area .comments-area .comment-metadata a {
  color: #666666;
  font-size: 11px;
}
.blog-details-area .comments-area .comment-metadata a:hover {
  color: var(--mainColor);
}
.blog-details-area .comments-area .comment-content p {
  font-size: 14px;
}
.blog-details-area .comments-area .comment-respond {
  margin-top: 30px;
}
.blog-details-area .comments-area .comment-respond .comment-reply-title {
  margin-bottom: 15px;
  position: relative;
  font-size: 22px;
}
.blog-details-area .comments-area .comment-respond .comment-reply-title::before {
  content: "";
  height: 25px;
  width: 3px;
  left: -25px;
  position: absolute;
  background: var(--mainColor);
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-area .comments-area .comment-respond .comment-reply-title #cancel-comment-reply-link {
  display: inline-block;
}
.blog-details-area .comments-area .comment-respond .comment-form {
  overflow: hidden;
}
.blog-details-area .comments-area .comment-respond .comment-form-comment {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}
.blog-details-area .comments-area .comment-respond label {
  display: block;
  font-weight: 500;
  color: var(--headingColor);
  margin-bottom: 5px;
}
.blog-details-area .comments-area .comment-respond input[type=datetime-local] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=datetime-local]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=week] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=week]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=month] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=month]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=text] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=text]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=email] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=email]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=url] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=url]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=password] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=password]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=search] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=search]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=tel] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=tel]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond input[type=number] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond input[type=number]:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond textarea {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .comments-area .comment-respond textarea:focus {
  border-color: var(--mainColor);
}
.blog-details-area .comments-area .comment-respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.blog-details-area .comments-area .comment-respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.blog-details-area .comments-area .comment-respond .comment-form-url {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.blog-details-area .comments-area .comment-respond .comment-form-cookies-consent {
  width: 100%;
  float: left;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}
.blog-details-area .comments-area .comment-respond .comment-form-cookies-consent input {
  position: absolute;
  left: 0;
  top: 6px;
}
.blog-details-area .comments-area .comment-respond .comment-form-cookies-consent label {
  display: inline-block;
  margin: 0;
  color: #666666;
  font-weight: normal;
}
.blog-details-area .comments-area .comment-respond .form-submit {
  float: left;
  width: 100%;
}
.blog-details-area .comments-area .comment-respond .form-submit input {
  background: var(--mainColor);
  border: none;
  color: var(--whiteColor);
  padding: 14px 30px 12px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  transition: 0.5s;
  font-weight: 500;
  font-size: 14px;
}
.blog-details-area .comments-area .comment-respond .form-submit input:hover {
  color: var(--whiteColor);
  background-color: var(--headingColor);
}
.blog-details-area .comments-area .comment-respond .form-submit input:focus {
  color: var(--whiteColor);
  background-color: var(--mainColor);
}
.blog-details-area .widget-area .widget {
  margin-top: 35px;
  background-color: var(--whiteColor);
  box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.08);
}
.blog-details-area .widget-area .widget .post-wrap {
  padding: 20px;
}
.blog-details-area .widget-area .widget:first-child {
  margin-top: 0;
}
.blog-details-area .widget-area .widget .widget-title {
  text-transform: capitalize;
  position: relative;
  font-size: 20px;
  margin-bottom: 0;
  background-color: #ececec;
  padding: 10px 20px;
  color: var(--headingColor);
  position: relative;
}
.blog-details-area .widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background-color: var(--mainColor);
}
.blog-details-area .widget-area .widget_search form {
  position: relative;
}
.blog-details-area .widget-area .widget_search form label {
  display: block;
  margin-bottom: 0;
}
.blog-details-area .widget-area .widget_search form .screen-reader-text {
  display: none;
}
.blog-details-area .widget-area .widget_search form .search-field {
  background-color: transparent;
  height: 50px;
  padding: 6px 15px;
  border: 1px solid #eeeeee;
  width: 100%;
  display: block;
  outline: 0;
  transition: 0.5s;
}
.blog-details-area .widget-area .widget_search form .search-field:focus {
  border-color: var(--mainColor);
}
.blog-details-area .widget-area .widget_search form button {
  position: absolute;
  right: 0;
  outline: 0;
  bottom: 0;
  height: 50px;
  width: 50px;
  border: none;
  color: var(--whiteColor);
  background-color: var(--mainColor);
  transition: 0.5s;
  cursor: pointer;
}
.blog-details-area .widget-area .widget_search form button:hover {
  background-color: var(--headingColor);
}
.blog-details-area .widget-area .widget-peru-posts-thumb {
  position: relative;
  overflow: hidden;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item {
  overflow: hidden;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .thumb .fullimage {
  width: 80px;
  height: 80px;
  display: inline-block;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
  position: relative;
  background-color: var(--headingColor);
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .thumb .fullimage.bg1 {
  background-image: url(assets/images/blog-details/popular-posts-1.png);
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .thumb .fullimage.bg2 {
  background-image: url(assets/images/blog-details/popular-posts-2.png);
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .thumb .fullimage.bg3 {
  background-image: url(assets/images/blog-details/popular-posts-3.png);
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .thumb .fullimage.bg4 {
  background-image: url(assets/images/blog-details/popular-posts-4.png);
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info {
  overflow: hidden;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info span {
  display: block;
  color: #666666;
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 14px;
  font-weight: 600;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info .title a {
  display: inline-block;
  color: #474c40;
}
.blog-details-area .widget-area .widget-peru-posts-thumb .item .info .title a:hover {
  color: var(--mainColor);
}
.blog-details-area .widget-area .widget_recent_entries ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .widget-area .widget_recent_entries ul li {
  position: relative;
  margin-bottom: 12px;
  color: #666666;
  padding-left: 17px;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 600;
}
.blog-details-area .widget-area .widget_recent_entries ul li:last-child {
  margin-bottom: 0;
}
.blog-details-area .widget-area .widget_recent_entries ul li::before {
  background: var(--mainColor);
  position: absolute;
  height: 7px;
  width: 7px;
  content: "";
  left: 0;
  top: 7px;
}
.blog-details-area .widget-area .widget_recent_entries ul li .post-date {
  display: block;
  font-size: 13px;
  color: #666666;
  margin-top: 4px;
}
.blog-details-area .widget-area .widget_categories ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .widget-area .widget_categories ul li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #666666;
  padding-left: 20px;
  font-size: 15px;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
}
.blog-details-area .widget-area .widget_categories ul li:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.blog-details-area .widget-area .widget_categories ul li::before {
  background: var(--mainColor);
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  left: 0;
  top: 9px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.blog-details-area .widget-area .widget_categories ul li a {
  display: block;
  color: #666666;
  font-weight: normal;
  font-size: 14px;
}
.blog-details-area .widget-area .widget_categories ul li a span {
  float: right;
  font-size: 13px;
}
.blog-details-area .widget-area .widget_categories ul li a:hover {
  color: var(--mainColor);
}
.blog-details-area .widget-area .widget_categories ul li .post-count {
  float: right;
}
.blog-details-area .widget-area .widget_meta ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.blog-details-area .widget-area .widget_meta ul li {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #666666;
  padding-left: 17px;
  font-size: 15px;
  font-weight: 500;
  border-bottom: 1px solid #efefef;
}
.blog-details-area .widget-area .widget_meta ul li a {
  color: var(--bodyColor);
}
.blog-details-area .widget-area .widget_meta ul li a:hover {
  color: var(--mainColor);
}
.blog-details-area .widget-area .widget_meta ul li:last-child {
  margin-bottom: 0;
}
.blog-details-area .widget-area .widget_meta ul li::before {
  background: var(--mainColor);
  position: absolute;
  height: 10px;
  width: 10px;
  content: "";
  left: 0;
  top: 9px;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.blog-details-area .widget-area .widget_tag_cloud .widget-title {
  margin-bottom: 0;
}
.blog-details-area .widget-area .tagcloud a {
  display: inline-block;
  color: #666666;
  font-weight: normal;
  font-size: 14px !important;
  padding: 7px 10px;
  border: 1px dashed #eeeeee;
  margin-top: 10px;
  margin-right: 10px;
  text-transform: capitalize;
}
.blog-details-area .widget-area .tagcloud a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
}
.blog-details-area .widget-area .tagcloud a:focus {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-color: var(--mainColor);
}
.blog-details-area .widget-area .widget_services_list ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
  background-color: var(--whiteColor);
}
.blog-details-area .widget-area .widget_services_list ul li a {
  display: block;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 35px;
  color: #5d6576;
  z-index: 1;
  font-weight: 500;
}
.blog-details-area .widget-area .widget_services_list ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #252920;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.blog-details-area .widget-area .widget_services_list ul li a i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-area .widget-area .widget_services_list ul li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  padding-left: 20px;
}
.blog-details-area .widget-area .widget_services_list ul li a:hover::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.blog-details-area .widget-area .widget_services_list ul li a.active {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  padding-left: 20px;
}
.blog-details-area .widget-area .widget_services_list ul li a.active::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.blog-details-area .widget-area .widget_services_list ul li:last-child a {
  border-bottom: none;
}
.blog-details-area .widget-area .widget_download ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.04);
  background-color: var(--whiteColor);
}
.blog-details-area .widget-area .widget_download ul li a {
  display: block;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 35px;
  color: #5d6576;
  z-index: 1;
  font-weight: 500;
}
.blog-details-area .widget-area .widget_download ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: #252920;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}
.blog-details-area .widget-area .widget_download ul li a i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
.blog-details-area .widget-area .widget_download ul li a:hover {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  padding-left: 20px;
}
.blog-details-area .widget-area .widget_download ul li a:hover::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.blog-details-area .widget-area .widget_download ul li a.active {
  background-color: var(--mainColor);
  color: var(--whiteColor);
  padding-left: 20px;
}
.blog-details-area .widget-area .widget_download ul li a.active::before {
  opacity: 1;
  visibility: visible;
  width: 5px;
}
.blog-details-area .widget-area .widget_download ul li:last-child a {
  border-bottom: none;
}
.blog-details-area .widget-area .info time {
  font-size: 13px;
  color: #929292;
  display: block;
  margin-bottom: 4px;
}

/*
FAQ Area Css
======================================================*/
.faq-area {
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 20px 4px rgba(0, 0, 0, 0.07);
}
.faq-area .section-tittle {
  text-align: center;
}
.faq-area .section-tittle h2 {
  margin-bottom: 30px;
}
.faq-area .faq-accordion .accordion {
  padding: 0;
  margin: 0;
}
.faq-area .faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 12px;
  position: relative;
}
.faq-area .faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-area .faq-accordion .accordion .accordion-item .accordion-title {
  padding: 15px 60px 15px 70px;
  color: var(--darkBlueColor);
  position: relative;
  background: var(--whiteColor);
  border-radius: 2px;
  font-size: 18px;
  font-weight: 600;
  display: block;
}
.faq-area .faq-accordion .accordion .accordion-item .accordion-title i {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--darkBlueColor);
  transition: 0.5s;
  font-size: 16px;
}
.faq-area .faq-accordion .accordion .accordion-item .accordion-title.active {
  background-color: var(--whiteColor);
}
.faq-area .faq-accordion .accordion .accordion-item .accordion-title.active i::before {
  content: "\ec4e";
}
.faq-area .faq-accordion .accordion .accordion-item .accordion-content {
  display: none;
  background-color: var(--whiteColor);
  margin-bottom: 0;
  padding: 15px 60px 15px 30px;
  font-size: 16px;
  z-index: 1;
  color: #50607f;
  position: relative;
}
.faq-area .faq-accordion .accordion .accordion-item .accordion-content.show {
  display: block;
}
.faq-area .faq-accordion .accordion .accordion-item .accordion-content::before {
  content: "";
  width: 75%;
  height: 2px;
  background: var(--bodyColor);
  top: 5px;
}
.faq-area .faq-accordion .accordion .icon {
  position: absolute;
  z-index: 2;
  width: 40px;
  height: 40px;
  background: #f5ebff;
  text-align: center;
  font-size: 25px;
  line-height: 40px;
  color: var(--darkBlueColor);
  top: 10px;
  left: 15px;
}

/*
Signup Area Css
======================================================*/
.signup-area .signup-form {
  box-shadow: 0 0 40px rgba(82, 85, 90, 0.1);
  padding: 40px;
  max-width: 540px;
  background: var(--whiteColor);
  border-radius: 10px;
  margin: auto;
}
.signup-area .signup-form .form-control {
  border-radius: 4px;
  height: 50px;
  padding: 10px 20px;
}
.signup-area .signup-form .form-control:focus {
  box-shadow: none;
}
.signup-area .signup-form .box-btn {
  margin-bottom: 30px;
}
.signup-area .signup-form .box-btn i {
  position: relative;
  top: 1px;
}
.signup-area .signup-form .google {
  padding: 15px 25px;
  background: #dd4b39;
}
.signup-area .signup-form .facebook {
  padding: 15px 17px;
  background: #3b5999;
}
.signup-area .signup-form .twitter {
  padding: 15px 25px;
  background: #55acee;
}
.signup-area .signup-form .signup-btn {
  padding: 15px 50px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.signup-area .privecy-txt {
  font-size: 14px;
}
.signup-area .privecy-txt label {
  position: relative;
  top: -2px;
  margin-left: 5px;
}
.signup-area p.al-acc {
  margin-bottom: 0;
  font-size: 14px;
}
.signup-area .section-tittle {
  margin-bottom: 30px;
}

/*
Price Area Css
======================================================*/
.price-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.price-area .single-price {
  text-align: center;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  transition: 0.3s;
}
.price-area .single-price .price-head {
  padding: 50px 0 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  background: var(--darkBlueColor);
}
.price-area .single-price .price-head h2 {
  font-size: 25px;
  margin-bottom: 0;
  color: var(--whiteColor);
}
.price-area .single-price .price-head p {
  font-size: 35px;
  font-weight: bold;
  color: var(--whiteColor);
  margin-bottom: 0;
}
.price-area .single-price .price-head p span {
  font-size: 16px;
}
.price-area .single-price .price-list {
  text-align: center;
  margin: 0;
  padding: 20px 0;
}
.price-area .single-price .price-list li p {
  margin-bottom: 0;
  padding: 3px 0;
  color: #616161;
}
.price-area .single-price .box-btn {
  background: var(--darkBlueColor);
  color: var(--whiteColor);
  margin-top: 0px;
  margin-bottom: 30px;
  border: 1px solid transparent;
}
.price-area .single-price:hover {
  transform: translateY(-10px);
}
.price-area .single-price:hover .box-btn {
  border: 1px dashed var(--mainColor);
  color: var(--mainColor);
  background: var(--whiteColor);
}
.price-area .single-price:hover .box-btn:hover {
  color: var(--whiteColor);
}

/*
Testimonials Area Css
======================================================*/
.testimonials-area .section-tittle {
  margin-bottom: 50px;
}
.testimonials-area .section-tittle p {
  margin: 0 15%;
}
.testimonials-area .single-testimonials {
  box-shadow: var(--boxShadow);
  padding: 20px 20px;
  position: relative;
  text-align: left;
  overflow: hidden;
  margin-bottom: 20px;
  transition: var(--transition);
}
.testimonials-area .single-testimonials .testimonials-head .testimonials-img {
  border: 1px solid var(--mainColor);
  padding: 6px;
  overflow: hidden;
}
.testimonials-area .single-testimonials .testimonials-head .content {
  margin-top: 0;
  position: relative;
  top: 2px;
}
.testimonials-area .single-testimonials .testimonials-head .content h2 {
  color: #181818;
  font-family: "Poppins", sans-serif;
  position: relative;
  font-size: 21px;
  margin-bottom: 5px;
}
.testimonials-area .single-testimonials .testimonials-head .content .rate {
  padding: 0;
  margin: 0;
}
.testimonials-area .single-testimonials .testimonials-head .content .rate li {
  display: inline-block;
}
.testimonials-area .single-testimonials .testimonials-head .content .rate li i {
  font-size: 15px;
  color: #ffc107;
}
.testimonials-area .single-testimonials .testimonials-head .content span {
  font-size: 15px;
}
.testimonials-area .single-testimonials .testimonials-foot p {
  margin-bottom: 0;
  color: #616161;
}
.testimonials-area .single-testimonials:hover {
  transform: translateY(-5px);
}
.testimonials-area .single-testimonials hr {
  margin-top: 18px !important;
  margin-bottom: 8px !important;
}

/*
Terms Area Css
======================================================*/
.terms-area,
.privecy-area {
  position: relative;
  overflow: hidden;
}
.terms-area h2,
.privecy-area h2 {
  color: var(--darkBlueColor);
  margin-bottom: 15px;
  font-size: 30px;
}

/*
Gallery Area Css
======================================================*/
.gallery-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.gallery-area .single-gall {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}
.gallery-area .single-gall .gall-img {
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.3s;
}
.gallery-area .single-gall .gall-img img {
  border-radius: 5px;
}
.gallery-area .single-gall .gall-content {
  position: absolute;
  text-align: center;
  background: var(--whiteColor);
  padding: 10px 20px;
  bottom: 20px;
  transition: 0.5s;
  bottom: -100px;
}
.gallery-area .single-gall .gall-content h3 {
  color: #181818;
  margin-bottom: 0;
  line-height: normal;
}
.gallery-area .single-gall:hover .gall-content {
  bottom: 0;
}
.gallery-area .single-gall:hover .gall-img {
  transform: scale(1.1);
}

/*
Team Area Css
======================================================*/
.team-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.team-area .section-tittle {
  text-align: center;
  margin-bottom: 30px;
}
.team-area .section-tittle p {
  margin: 0 15%;
}
.team-area .single-team {
  position: relative;
  overflow: hidden;
  box-shadow: var(--boxShadow);
  margin-bottom: 30px;
  transition: var(--transition);
}
.team-area .single-team .team-img {
  height: 400px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  transition: 0.3s;
  background: #121e4c;
}
.team-area .single-team .team-img img {
  border-radius: 5px;
  transition: 0.3s;
}
.team-area .single-team .team-content {
  padding: 20px;
}
.team-area .single-team .team-content h3 {
  color: #121e4c;
  text-align: center;
  margin-bottom: 5px;
}
.team-area .single-team .team-content p {
  text-align: center;
  color: #181818;
  margin-bottom: 10px;
}
.team-area .single-team .team-content .social {
  text-align: center;
  margin: 0;
  padding: 0;
  width: 100%;
  transition: 0.3s;
}
.team-area .single-team .team-content .social li {
  display: inline-block;
  margin-right: 10px;
}
.team-area .single-team .team-content .social li a {
  width: 30px;
  height: 30px;
  background: #121e4c;
  border-radius: 50%;
  line-height: 32px;
  text-align: center;
  transition: 0.3s;
  color: #f5faff;
  transition: 0.3s;
}
.team-area .single-team .team-content .social li a i {
  font-size: 15px;
}
.team-area .single-team .team-content .social li a:hover {
  transform: translateY(-3px);
}
.team-area .single-team:hover {
  transform: translateY(-5px);
}

/*
Error Area Css
======================================================*/
.error-area {
  position: relative;
  text-align: center;
}
.error-area h3 {
  color: #e6335a;
  margin-top: 30px;
  font-size: 30px;
}

/*
Contact Area Css
======================================================*/
@keyframes updown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.home-contact-area .content {
  box-shadow: var(--boxShadow);
  background-color: var(--whiteColor);
  border-radius: var(--borderRadius);
  padding: 30px;
}
.home-contact-area .content input.form-control {
  height: 55px;
  border: 1px solid #eeeeee;
  margin-bottom: 20px;
  padding-left: 6px;
  background-color: #f9f9f9;
}
.home-contact-area .content input.form-control:focus {
  box-shadow: none;
  color: black;
  border-color: var(--mainColor);
}
.home-contact-area .content textarea.form-control {
  width: 100%;
  border: 1px solid #eeeeee;
  max-height: 170px;
  min-height: 170px;
  box-shadow: none;
  padding-left: 6px;
  padding-top: 15px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}
.home-contact-area .content textarea.form-control:focus {
  box-shadow: none;
  color: black;
  border-color: var(--mainColor);
}
.home-contact-area .content button {
  border: none;
  padding: 15px 35px;
  display: table;
  text-align: center;
  margin: 0 auto -8px;
}
.home-contact-area .list-unstyled {
  color: #dc3545;
  margin-top: -13px;
  margin-bottom: -8px;
  font-size: 13px;
}
.home-contact-area .text-danger {
  color: #dc3545 !important;
  margin-top: 15px;
}
.home-contact-area .text-success {
  color: green !important;
}
.home-contact-area #msgSubmit {
  text-align: center;
}

.home-2-contact .content {
  box-shadow: var(--boxShadow);
  padding: 30px;
  border-radius: var(--borderRadius);
}
.home-2-contact .content input.form-control {
  border-radius: var(--borderRadius);
}
.home-2-contact .content textarea.form-control {
  border-radius: 4px;
}
.home-2-contact .contact-img {
  background: url(assets/images/contact-img2.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  animation: none;
}
.home-2-contact .contact-img img {
  display: none;
}

.contact-area .single-contact {
  text-align: center;
  position: relative;
  box-shadow: var(--boxShadow);
  padding: 30px;
  height: 17.5rem;
  border-radius: 10px;
  transition: var(--transition);
  margin-bottom: 30px;
}
.contact-area .single-contact .icon {
  width: 45px;
  height: 45px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.contact-area .single-contact .icon i {
  font-size: 23px;
  line-height: 45px;
}
.contact-area .single-contact:hover {
  transform: translateY(-5px);
}

/*
Footer Area Css
======================================================*/
.footer-area {
  position: relative;
  overflow: hidden;
  background: url(assets/images/footer-bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
.footer-area::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url(assets/images/footer-bg1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: imageChange 1s linear 1s infinite;
}
@keyframes imageChange {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.footer-area .newsletter-form {
  margin-bottom: 25px;
}
.footer-area .content {
  margin-bottom: 30px;
  margin-top: -20px;
}
.footer-area .content p {
  color: var(--whiteColor);
  margin-top: 20px;
}
.footer-area .content .subscribe {
  position: relative;
  max-width: 100%;
}
.footer-area .content .subscribe h4 {
  font-size: 24px;
  color: #f5faff;
}
.footer-area .content .subscribe input#emails {
  position: relative;
  height: 50px;
  padding-left: 20px;
  border-radius: var(--borderRadius);
  color: var(--whiteColor);
  margin: 20px 0;
  margin-bottom: 10px;
}
.footer-area .content .subscribe input#emails::placeholder {
  color: var(--whiteColor);
}
.footer-area .content .subscribe input#emails:focus {
  box-shadow: none;
}
.footer-area .content .subscribe .newsletter-form {
  position: relative;
}
.footer-area .content .subscribe button {
  position: absolute;
  top: 37px;
  right: 0;
  border: none;
  height: 50px;
}
.footer-area .content .subscribe button::before {
  background-color: var(--whiteColor);
}
.footer-area .content .subscribe button::after {
  background-color: var(--whiteColor);
}
.footer-area .content .subscribe button:hover {
  background-color: var(--mainColor);
  color: var(--mainColor);
}
.footer-area .content .subscribe #validator-newsletter {
  display: inline-block;
  color: #e6335a;
}
.footer-area .content .social {
  margin: 0;
  padding: 0;
}
.footer-area .content .social li {
  display: inline-block;
  margin-right: 10px;
}
.footer-area .content .social li a {
  width: 35px;
  height: 35px;
  background: var(--mainColor);
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  transition: 0.3s;
  color: #f5faff;
  transition: 0.3s;
}
.footer-area .content .social li a i {
  font-size: 18px;
}
.footer-area .content .social li a:hover {
  transform: translateY(-3px);
}
.footer-area .content h3 {
  font-size: 25px;
  color: #f5faff;
  margin-bottom: 25px;
  position: relative;
  z-index: 1;
  margin: 15px;
  padding-left: 20px;
}
.footer-area .content h3::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 100%;
  background: #e6335a;
  left: -15px;
  z-index: -1;
}
.footer-area .content h3::after {
  position: absolute;
  content: "";
  background: var(--darkBlueColor);
  width: 7px;
  height: 7px;
  left: -15px;
  top: 0;
  right: 0;
  overflow: hidden;
  animation: line 8s infinite;
}
@keyframes line {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}
.footer-area .content .footer-list {
  margin: 15px;
  padding: 0;
}
.footer-area .content .footer-list li {
  line-height: 1.8;
  margin-bottom: 8px;
  position: relative;
  padding-left: 20px;
}
.footer-area .content .footer-list li a {
  color: #f5faff;
  font-size: 15px;
  transition: 0.3s;
}
.footer-area .content .footer-list li a i {
  position: absolute;
  top: 4px;
  left: 0;
  color: var(--whiteColor);
  display: inline-block;
  font-size: 15px;
}
.footer-area .content .footer-list li a:hover {
  letter-spacing: 0.02em;
}
.footer-area .content .foot-social {
  position: relative;
  padding-left: 20px;
}
.footer-area .content .foot-social li {
  position: relative;
  padding-left: 48px;
  color: #f5faff;
  font-size: 14px;
  margin-bottom: 20px;
}
.footer-area .content .foot-social li i {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 4px;
  width: 30px;
  height: 30px;
  background: var(--mainColor);
  border-radius: 5px;
  line-height: 31px;
  text-align: center;
  transition: 0.3s;
  color: #f5faff;
}
.footer-area .copy-area {
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px solid #2a355e;
}
.footer-area .copy-area .menu {
  margin: 0;
  padding: 0;
}
.footer-area .copy-area .menu li {
  display: inline-block;
  margin-right: 25px;
  font-size: 15px;
}
.footer-area .copy-area .menu li a {
  color: #f5faff;
}
.footer-area .copy-area .menu li:last-child {
  margin-right: 0;
}
.footer-area .copy-area p {
  font-size: 15px;
  color: #f5faff;
  margin-bottom: 0;
  padding-left: 29rem;
}
.footer-area .copy-area p a {
  color: #e6335a;
  font-size: 16px;
  font-weight: 600;
}
.footer-area .copy-area p a:hover {
  color: var(--bodyColor);
}
.footer-area .copy-area .right {
  float: right;
}

.footer-area-2 {
  background: var(--darkBlueColor);
  position: relative;
  z-index: 1;
}
.footer-area-2::before {
  display: none;
}

/* 
Page Title Area Css
=====================================================*/
.page-title-area {
  position: relative;
  overflow: hidden;
  padding-top: 110px;
  padding-bottom: 70px;
  text-align: center;
  background-color: var(--darkBlueColor);
  height: 100%;
  z-index: 1;
}
.page-title-area .page-title-content h2 {
  margin-bottom: 15px;
  color: var(--whiteColor);
  font-size: 40px;
  font-weight: 700;
}
.page-title-area .page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 0;
}
.page-title-area .page-title-content ul li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding-right: 20px;
  padding-left: 20px;
  color: var(--whiteColor);
}
.page-title-area .page-title-content ul li::before {
  content: "";
  position: absolute;
  top: 10px;
  right: -4px;
  background-color: #70a1ff;
  width: 8px;
  height: 8px;
}
.page-title-area .page-title-content ul li:last-child::before {
  display: none;
}
.page-title-area .page-title-content ul li a {
  color: var(--whiteColor);
}
.page-title-area .page-title-content ul li a:hover {
  color: var(--mainColor);
}
.page-title-area .page-title-content ul .active {
  color: #70a1ff;
}
.page-title-area .page-shape .shape1 {
  position: absolute;
  top: 55%;
  left: 5%;
  animation: animationFramesOne 20s linear infinite;
}
.page-title-area .page-shape .shape6 {
  position: absolute;
  bottom: 27%;
  right: 22%;
  animation: animationFramesOne 15s linear infinite;
}
.page-title-area .page-shape .shape3 {
  position: absolute;
  left: 25%;
  bottom: 15%;
  animation: rotaed360 6s infinite linear;
}
.page-title-area .page-shape .shape4 {
  position: absolute;
  top: 27%;
  left: 48%;
  animation: rotaed360 6s infinite linear;
}
.page-title-area .page-shape .shape5 {
  position: absolute;
  top: 15%;
  right: 3%;
  animation: rotaed360 6s infinite linear;
}

/* 
Pagenavigation Area Css
=====================================================*/
.page-navigation-area {
  text-align: center;
  margin: 20px auto 0;
  display: table;
}
.page-navigation-area .page-link {
  background-color: var(--mainColor);
  box-shadow: var(--boxShadow);
  width: 40px;
  height: 40px;
  line-height: 24px;
  transition: var(--transition);
  font-weight: 700;
  color: var(--whiteColor) !important;
  line-height: 23px;
  border-radius: 50%;
}
.page-navigation-area .page-link i {
  margin-right: -4px;
  font-size: 21px;
}
.page-navigation-area .page-link:hover {
  color: var(--whiteColor);
  background-color: var(--darkBlueColor);
}
.page-navigation-area .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}
.page-navigation-area .page-link.page-links i::before {
  margin-left: -4px;
}
.page-navigation-area .page-item {
  padding: 0 8px 0;
}
.page-navigation-area .page-item:first-child .page-link {
  border-radius: 50%;
}
.page-navigation-area .page-item:last-child .page-link {
  border-radius: 50%;
}
.page-navigation-area .page-item.active .page-link {
  z-index: 1;
  color: var(--whiteColor);
  background-color: var(--darkBlueColor);
}

/*
Project Area Six Style
======================================================*/
.project-area-six {
  text-align: center;
}
.project-area-six .single-project {
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  margin-bottom: 30px;
}
.project-area-six .single-project .project-image {
  position: relative;
  transition: all 0.5s;
  height: 380px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.project-area-six .single-project .project-image.bg-1 {
  background-image: url(assets/images/gallery/1.jpg);
}
.project-area-six .single-project .project-image.bg-2 {
  background-image: url(assets/images/gallery/2.jpg);
}
.project-area-six .single-project .project-image.bg-3 {
  background-image: url(assets/images/gallery/3.jpg);
}
.project-area-six .single-project .project-image.bg-4 {
  background-image: url(assets/images/gallery/4.jpg);
}
.project-area-six .single-project .project-image.bg-5 {
  background-image: url(assets/images/gallery/5.jpg);
}
.project-area-six .single-project .project-image img {
  transition: all 9s;
}
.project-area-six .single-project .project-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 15px;
  transition: var(--transition);
  z-index: 2;
  text-align: center;
  width: 93%;
  height: 90%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(37, 36, 94, 0.9);
  transform: scaleY(0);
}
.project-area-six .single-project .project-content span {
  color: var(--whiteColor);
  font-weight: 500;
  display: block;
  margin-bottom: 6px;
}
.project-area-six .single-project .project-content h3 {
  margin-bottom: 4px;
  transition: var(--transition);
}
.project-area-six .single-project .project-content h3:hover a {
  text-decoration: underline;
}
.project-area-six .single-project .project-content h3 a {
  color: var(--whiteColor);
}
.project-area-six .single-project .project-content h3:hover a {
  color: var(--whiteColor);
}
.project-area-six .single-project .project-content .read-more:hover {
  color: var(--whiteColor);
}
.project-area-six .single-project:hover .project-content {
  transform: scaleY(1);
}
.project-area-six .single-project:hover .project-image {
  transform: scale(2.2) rotate(4deg);
}

/*
Pricing Area Style
======================================================*/
.single-pricing {
  text-align: center;
  background-color: var(--whiteColor);
  box-shadow: var(--boxShadow);
  padding: 30px;
  border-radius: var(--borderRadius);
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
  border-radius: 50px 0 50px 0;
  transition: var(--transition);
}
.single-pricing .pricing-top-heading {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.single-pricing .pricing-top-heading h3 {
  font-size: 25px;
}
.single-pricing .pricing-top-heading p {
  font-weight: 600;
  font-size: 14px;
}
.single-pricing span {
  font-size: 30px;
  font-family: var(--headingFontFamily);
  color: var(--headingColor);
  font-weight: 700;
  display: block;
  line-height: 1;
  margin: 0 auto 20px;
  background-color: var(--whiteColor);
  box-shadow: var(--boxShadow);
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 50%;
}
.single-pricing span sub {
  font-size: 14px;
  color: var(--bodyColor);
}
.single-pricing ul {
  text-align: left;
  max-width: 200px;
  margin: 0 auto 25px;
}
.single-pricing ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 25px;
  font-weight: 600;
}
.single-pricing ul li:last-child {
  margin-bottom: 0;
}
.single-pricing ul li i {
  font-size: 12px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #daf6fd;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 0;
  color: #1dd3f8;
}
.single-pricing .default-btn {
  padding: 15px 35px;
}
.single-pricing:hover {
  transform: translateY(-5px);
}

.popular {
  position: absolute;
  top: -15px;
  right: -50px;
  z-index: 1;
  background-color: red;
  color: var(--whiteColor);
  padding: 35px 40px 10px 35px;
  transform: rotate(45deg);
}

.tab.quote-list-tab {
  text-align: center;
}
.tab.quote-list-tab .tabs {
  border-radius: 10px;
  display: inline-block;
  border: 1px solid var(--mainColor);
  margin-bottom: 30px;
}
.tab.quote-list-tab .tabs li {
  display: inline-block;
  padding: 0 10px;
  font-family: var(--headingFontFamily);
  color: var(--headingColor);
  font-size: 20px;
  font-weight: 700;
}
.tab.quote-list-tab .tabs li a {
  color: var(--mainColor);
  padding: 10px 20px;
}
.tab.quote-list-tab .tabs .current {
  background-color: var(--mainColor);
  border-radius: 10px;
}
.tab.quote-list-tab .tabs .current a {
  color: var(--whiteColor);
}

.tab .tabs_item {
  display: none;
}

.tab .tabs_item:first-child {
  display: block;
}

/*
Services Details Area Style
=====================================================*/
.services-details-area .scrives-item-3 .social-link {
  line-height: 1;
}
.services-details-area .scrives-item-3 .social-link li {
  display: inline-block;
  margin-right: 5px;
}
.services-details-area .scrives-item-3 .social-link li a {
  width: 30px;
  height: 30px;
  line-height: 38px;
  background-color: var(--mainColor);
  color: var(--whiteColor);
  border-radius: 50%;
  text-align: center;
}
.services-details-area .scrives-item-3 .social-link li a i {
  font-size: 20px;
}
.services-details-area .scrives-item-3 .social-link li a:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.services-details-area .scrives-item-3 .share {
  float: right;
}
.services-details-area .scrives-item-3 .share a {
  font-size: 20px;
  font-weight: 600;
}
.services-details-area .scrives-item-3 .share a i {
  font-size: 20px;
  position: relative;
  top: 3px;
}
.services-details-area .services-img.mb {
  margin-bottom: 30px;
}
.services-details-area .services-item-wrap {
  margin-bottom: 30px;
}
.services-details-area .services-details-text h2 {
  font-size: 35px;
}

/*
Banner Area Three Style
======================================================*/
.main-banner-three {
  background-color: #f5faff;
  position: relative;
  z-index: 1;
}
.main-banner-three .main-banner-content {
  width: 100%;
  height: 100%;
}
.main-banner-three .main-banner-content .content {
  max-width: 630px;
  margin-left: auto;
  margin-top: 90px;
}
.main-banner-three .main-banner-content .content h1 {
  font-size: 55px;
  margin-bottom: 15px;
}
.main-banner-three .main-banner-content .content p {
  margin-bottom: 35px;
}
.main-banner-three .main-banner-content .content .default-btn {
  margin-top: 20px;
}
.main-banner-three .main-banner-content .content .slider-btn-wrap .box-btn.border-btn {
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  margin-left: 20px;
}
.main-banner-three .main-banner-content .content .slider-btn-wrap .box-btn.border-btn:hover {
  color: var(--whiteColor);
  border-color: var(--headingColor);
}
.main-banner-three .banner-image-slider .banner-image {
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.main-banner-three .banner-image-slider .banner-image.banner-slider-bg-1 {
  background-image: url(assets/images/home-three/banner-img.jpg);
}
.main-banner-three .banner-image-slider .banner-image.banner-slider-bg-2 {
  background-image: url(assets/images/home-three/banner-img-2.jpg);
}
.main-banner-three .banner-image-slider .owl-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}
.main-banner-three .banner-image-slider .owl-dots .owl-dot span {
  width: 20px;
  height: 10px;
  transition: var(--transition);
}
.main-banner-three .banner-image-slider .owl-dots .owl-dot.active span {
  background-color: var(--mainColor);
  width: 25px;
}
.main-banner-three .banner-image-slider .owl-dots .owl-dot:hover span {
  background-color: var(--mainColor);
}
.main-banner-three .banner-image-slider .owl-prev {
  background-color: var(--mainColor) !important;
  position: absolute;
  bottom: 50%;
  left: 0;
  padding: 0 !important;
  transition: var(--transition);
  transform: translateY(35px);
  opacity: 0;
}
.main-banner-three .banner-image-slider .owl-prev i {
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--whiteColor);
  display: inline-block;
}
.main-banner-three .banner-image-slider .owl-prev:hover {
  background-color: var(--darkBlueColor);
}
.main-banner-three .banner-image-slider .owl-prev:hover i {
  color: var(--whiteColor);
}
.main-banner-three .banner-image-slider .owl-next {
  background-color: var(--mainColor) !important;
  position: absolute;
  bottom: 50%;
  right: 0;
  padding: 0 !important;
  transition: var(--transition);
  transform: translateY(35px);
  opacity: 0;
}
.main-banner-three .banner-image-slider .owl-next i {
  font-size: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: var(--whiteColor);
  display: inline-block;
}
.main-banner-three .banner-image-slider .owl-next:hover {
  background-color: var(--darkBlueColor) !important;
}
.main-banner-three .banner-image-slider .owl-next:hover i {
  color: var(--whiteColor);
}
.main-banner-three .banner-image-slider:hover .owl-prev {
  opacity: 1;
}
.main-banner-three .banner-image-slider:hover .owl-next {
  opacity: 1;
}

.why_choose_us {
  max-width: 100%;
  height: 63.7vh;
}

.ask_us {
  max-width: 100%;
  height: 32rem;
}

.who_we_are {
  max-width: 100%;
  margin-top: 13px;
}

.about_image {
  max-width: 100%;
  height: 22rem;
  margin-top: 45px;
  margin-left: -2%;
}

.logo_image {
  max-width: 75px;
  margin-left: 23px;
}

.contact_us {
  max-width: 100%;
  height: 32rem;
}

.referral_us {
  max-width: 100%;
  height: 40rem;
  padding-top: 1rem;
}

#referralForm {
  padding-top: 1rem;
}

.footer_logo {
  width: 300px;
}

.model_img {
  max-width: 54px;
  margin-left: -1px;
  margin-top: 9px;
}

.list_items {
  text-align: left;
  padding-left: 30px;
  list-style-type: disc;
}

.item {
  cursor: pointer;
  margin-bottom: 30px;
}

.service {
  text-align: center;
  padding-bottom: 21px;
  margin-top: -30px;
}

.service1 {
  text-align: center;
  margin-top: 15px;
  padding-bottom: 30px;
}

.main_img {
  max-width: 90%;
  height: auto;
  padding-top: 40px;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .logo_image {
    max-width: 50px;
  }
}
.counter-section-area {
  position: relative;
  margin-top: 25px;
}

.counter-section-area.style-01 {
  margin-top: 120px;
}

.counter-section-area.style-02 {
  margin-top: 105px;
}

.counter-section-area.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 120px 0px;
}

.counter-section-area .counter-shape {
  position: absolute;
  top: 0px;
  left: 31%;
  opacity: 0.1;
}

.counter-section-area .counter-shape.style-01 {
  top: 0;
  left: -1%;
}

.counter-section-area .counter-shape-01 {
  position: absolute;
  top: 0px;
  left: 34%;
  opacity: 0.1;
}

.counter-section-area .counter-shape-01.style-01 {
  top: 0;
  left: 2%;
}

.counter-section-area .counter-shape-02 {
  position: absolute;
  bottom: 0;
  right: 30%;
  opacity: 0.1;
}

.counter-section-area .counter-shape-02.style-01 {
  bottom: 0;
  right: -1%;
}

.counter-section-area .counter-shape-03 {
  position: absolute;
  bottom: 0;
  right: 33%;
  opacity: 0.1;
}

.counter-section-area .counter-shape-03.style-01 {
  bottom: 0;
  right: 2%;
}

.counter-section-area #Counter-text {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 40px;
}

.counter-section-inner {
  background: #fff;
  -webkit-box-shadow: 0px 12px 40px rgba(221, 19, 26, 0.28);
  box-shadow: 0px 12px 40px rgba(188, 189, 194, 0.7294117647);
  padding: 60px;
  border-radius: 10px;
  margin-bottom: 39px;
}

.counter-section-inner.political {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.counter-item-list li {
  width: 33.3333333333%;
}

.counter-item-list li + li {
  border-left: 1px solid #a8aab1;
}

.count-num {
  font-size: 65px;
  color: rgb(5, 5, 98);
  font-weight: 700;
}

.single-counterup-01 {
  text-align: center;
  border-right: 1px dashed;
}

.single-counterup {
  text-align: center;
}

@media (max-width: 992px) {
  .single-counterup-01 {
    border-right: none;
  }
}
@media (max-width: 767px) {
  .banner-area .main_img {
    margin-top: -44px;
    max-width: 18rem;
    margin-left: 6px;
  }
}
.we_do_img {
  max-width: 50%;
  height: auto;
}

.industry_img {
  max-width: 100%;
  height: 14rem;
}

.twitter {
  max-width: 10px;
  height: 2px;
}

#industry_background {
  background-color: aliceblue;
}

.consult_cycle {
  max-height: 60px;
  max-width: 100%;
}

#zeqube {
  margin-top: 10px;
}

#Kreativ3D {
  margin-top: -82px;
  height: 14rem;
}

.comming-soon-section {
  /* height: 100vh; */
  overflow: hidden;
}

.comming-soon-section .bg-comming {
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
}

.comming-soon-item {
  margin-top: -70px;
  padding-left: 120px;
  padding-right: 120px;
}

.comming-soon-item .logo {
  margin-bottom: 60px;
}

.comming-soon-item .content .title {
  font-size: 40px;
  line-height: 1.26;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 32px;
  text-transform: uppercase;
}

.comming-soon-item .content p {
  font-size: 16px;
  line-height: 1.6;
  font-weight: 400;
  color: #6B6B6B;
  margin-bottom: 25px;
}

.comming-soon-item .counter-single-item {
  background: #FDF2F2;
  border: 1px solid #FDF2F2;
  padding: 15px 40px 10px 40px;
}

.comming-soon-item .counter-single-item .counter-item h6 {
  background: transparent;
  color: var(--heading-color);
}

.comming-soon-item .subscribe-form {
  margin-top: 40px;
}

.comming-soon-item .subscribe-form.style-01 {
  padding-right: 50px;
}

.comming-soon-item .subscribe-form p {
  font-size: 16px;
  font-weight: 400;
  color: #6B6B6B;
  margin-bottom: 15px;
}

.comming-soon-item .subscribe-form .form-group {
  position: relative;
  z-index: 0;
  margin-bottom: 0;
}

.comming-soon-item .subscribe-form .form-group .form-control {
  height: 60px;
  border: 1px solid #CCCCCC;
  border-radius: 0px;
  font-size: 16px;
  font-weight: 400;
}

.comming-soon-item .subscribe-form .form-group .form-control::-webkit-input-placeholder {
  color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .form-control::-moz-placeholder {
  color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .form-control:-ms-input-placeholder {
  color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .form-control::-ms-input-placeholder {
  color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .form-control::placeholder {
  color: #D6D6D6;
}

.comming-soon-item .subscribe-form .form-group .btn-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.comming-soon-item .subscribe-form .form-group .btn-wrapper .boxed-btn {
  border-radius: 0;
  padding: 17px 17px;
  font-weight: 700;
  text-transform: uppercase;
  background: #051242;
  color: white;
}

.comming-soon-item .subscribe-form .form-group .btn-wrapper .boxed-btn .a :hover {
  color: #1dd3f8;
}

.comming-soon-item .subscribe-form .form-group .btn-wrapper .boxed-btn i {
  margin-left: 10px;
}

.comming-soon-item .social-area {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 60px;
}

.comming-soon-item .social-area p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #6B6B6B;
  margin-right: 10px;
}

.comming-soon-item .social-area li {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #C9C9C9;
  border-radius: 3px;
  color: #505050;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}

.comming-soon-item .social-area li:hover {
  border: 1px solid var(--main-color-one);
  background: var(--main-color-one);
  color: #fff;
}

.comming-soon-item .social-area li + li {
  margin-left: 10px;
}

@media only screen and (max-width: 1440px) {
  .comming-soon-item {
    margin-top: 20px;
    padding-left: 40px;
    padding-right: 120px;
  }
}
@media only screen and (max-width: 990px) {
  .comming-soon-item {
    margin-top: 20px;
    padding-left: 40px;
    padding-right: 120px;
  }
}
@media (max-width: 2560px) and (min-width: 1660px) {
  .comming-soon-item {
    margin-top: -80px;
    padding-left: 20.3rem;
  }
  .banner-area .banner-content {
    max-width: 635px;
    margin-left: 19rem;
    margin-top: 30px;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .comming-soon-item {
    margin-top: -70px;
    padding-left: 50px;
    margin-right: -30rem;
  }
}
@media only screen and (max-width: 767px) {
  /* Comming Soon */
  .comming-soon-item {
    margin-top: -70px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .footer-area .content .foot-social li {
    position: relative;
    padding-left: 42px;
    color: #f5faff;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .comming-soon-item .element-01,
  .comming-soon-item .element-02,
  .comming-soon-item .element-03,
  .comming-soon-item .element-04,
  .comming-soon-item .element-05 {
    display: none;
  }
}
.single-service .service-content .tech {
  margin-bottom: 10px;
  height: 12rem;
}

.testimonials h2 {
  font-size: 40px;
  text-transform: capitalize;
  color: var(--headingColor);
  margin-top: 50px;
}

@media (max-width: 991px) {
  .who_we_are {
    margin-bottom: 10px;
  }
}
.technology-content {
  padding-top: 10px;
}

.home-company-area1 {
  padding-top: 1.5rem;
  padding-bottom: 3rem;
}

@media (max-width: 991px) {
  .service {
    text-align: center;
    padding-bottom: 21px;
    margin-top: -20px;
  }
}
@media (max-width: 991px) {
  .single-service .service-content p {
    margin-bottom: 10px;
    height: 8.5rem;
  }
}
@media (max-width: 991px) {
  .single-service {
    position: relative;
    text-align: center;
    padding: 23px;
    margin-bottom: 17px;
    height: 21rem;
    transition: var(--transition);
    border-radius: var(--borderRadius);
    z-index: 1;
    overflow: hidden;
    box-shadow: var(--boxShadow);
  }
}
.about_image2 {
  display: none;
}

@media (min-width: 300px) and (max-width: 767px) {
  .about_image2 {
    max-width: 100%;
    height: 20rem;
    margin-top: 5px;
    display: block;
  }
}
@media (max-width: 991px) {
  .about_image {
    max-width: 100%;
    height: 25rem;
    margin-top: 30px;
  }
}
#client-area1 {
  background: aliceblue;
}

@media (max-width: 490px) {
  .single-client {
    padding: 20px;
    height: 27rem;
  }
}
@media (max-width: 991px) {
  .single-service1 {
    margin-bottom: 40px;
    height: 25rem;
  }
}
#tech {
  margin-top: 35px;
}

@media (max-width: 991px) {
  #tech {
    margin-top: 9px;
  }
}
.arrow {
  max-width: 20px;
}

.arrow:hover {
  color: white;
}

#arkitin-text {
  font-weight: 600;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 30px;
}

@media (min-width: 991px) and (max_width: 1199) {
  .navbar-area .nav-right input.search {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #ser_item {
    font-size: 15px !important;
    line-height: 2.5 !important;
  }
}
@media (max-width: 768px) {
  .banner-area .banner-content h1 {
    font-size: 35px !important;
    margin-bottom: 15px !important;
  }
}
@media (max-width: 542) {
  .banner-area .banner-content {
    margin-top: 0;
    text-align: left !important;
    max-width: 70% !important;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .ask_us {
    max-width: 100%;
    height: 29.8rem;
  }
  .home-contact-area .content textarea.form-control {
    width: 100%;
    border: 1px solid #eeeeee;
    max-height: 80px;
    min-height: 80px;
    box-shadow: none;
    padding-left: 7px;
    padding-top: 15px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  .home-contact-area .content input.form-control {
    height: 40px;
    border: 1px solid #eeeeee;
    margin-bottom: 20px;
    padding-left: 7px;
    background-color: #f9f9f9;
  }
  .banner-area .banner-content {
    max-width: 635px;
    margin-left: 0 !important;
    margin-top: 30px;
  }
  .banner-area .banner-content {
    margin-top: 0;
    text-align: left !important;
    max-width: 50% !important;
  }
}
@media (min-width: 420px) and (max-width: 575px) {
  .single-service {
    height: 10rem;
  }
}
#ser_item {
  font-size: 19px;
  line-height: 2.5;
}

@media (min-width: 576px) and (max-width: 990px) {
  .single-service {
    height: 20rem;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .single-service .service-content p {
    margin-bottom: 10px;
    height: 13rem;
    text-align: justify;
  }
}
@media (min-width: 576px) and (max-width: 766px) {
  .single-service {
    padding: 20px;
    height: 20.5rem;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .single-service .service-content p {
    margin-bottom: 10px;
    height: 10.5rem;
  }
  .single-service .service-content .tech {
    margin-bottom: 10px;
    height: 12rem;
  }
  .single-service .service-content .tech {
    padding: 5px;
    height: 8.5rem;
  }
  .why_choose_us {
    max-width: 100%;
    height: 37.2vh;
  }
}
@media (min-width: 990px) and (max-width: 991px) {
  .single-service .service-content p {
    margin-bottom: 10px;
    height: 10rem;
    text-align: left;
  }
  .single-service .service-content .tech {
    margin-bottom: 10px;
    height: 10rem;
  }
}
@media (min-width: 990px) and (max-width: 991px) {
  .single-service {
    padding: 20px;
    max-height: 25rem;
  }
}
@media (min-width: 767px) and (max-width: 989px) {
  .single-service {
    padding: 20px;
    height: 21rem;
  }
  .home-company-area .company-content .box-btn {
    margin-left: 17rem;
  }
  .single-service .service-content p {
    margin-bottom: 10px;
    height: 7rem;
  }
  #ser_item {
    font-size: 18px;
    line-height: 2.5;
  }
  .single-service .service-content .tech {
    margin-bottom: 10px;
    height: 7rem;
  }
}
@media (min-width: 576px) and (max-width: 766px) {
  .single-service {
    padding: 20px;
    max-height: 22.8rem;
  }
  .single-service .service-content p {
    margin-bottom: 10px;
    height: 11.5rem;
    text-align: left;
  }
  #ser_item {
    font-size: 18px;
    line-height: 2.5;
  }
  .single-service .service-content .tech {
    margin-bottom: 10px;
    height: 11.8rem;
  }
}
@media (min-width: 350px) and (max-width: 575px) {
  .single-service {
    padding: 20px;
    max-height: 21.8rem;
  }
  .single-service .service-content .tech {
    margin-bottom: 10px;
    height: 9rem;
  }
}
@media (min-width: 768px) and (max-width: 820px) {
  #future {
    margin-left: -20rem;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  p {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .contact-area .single-contact {
    height: 14.5rem;
  }
  .navbar-area .active .collapse:not(.show) {
    display: block;
    margin-left: 70%;
    padding-left: 2%;
    margin-right: -20%;
  }
  #who1 {
    text-align: center;
  }
  .about_image {
    max-width: 100%;
    height: 20rem;
    margin-top: 36px;
  }
  .single-client {
    height: 15rem;
  }
  .single-client1 {
    height: 15rem;
  }
  .single-client2 {
    height: 15rem;
  }
  .footer-area .copy-area p {
    padding-left: 14rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .contact_us {
    max-width: 100%;
    height: 29.8rem;
  }
  .referral_us {
    max-width: 100%;
    height: 40.5rem;
    padding-top: 1rem;
  }
  .footer-area .content .subscribe button {
    position: absolute;
    top: -9.5px;
    height: 51px;
  }
  #our1 {
    margin-bottom: 0;
    text-align: center;
  }
  .who_we_are {
    max-width: 100%;
    margin-top: -33px;
  }
}
#home-company-area2 {
  padding-top: 0.5rem;
  padding-bottom: 3rem;
}

@media (max-width: 991px) and (min-width: 576px) {
  #arkitin-model1 {
    margin-left: -65px;
  }
  #arkitin-model2 {
    margin-left: -12px;
  }
  #arkitin-model3 {
    margin-left: 26px;
  }
  #arkitin-model4 {
    margin-left: 44px;
  }
  #arkitin-model5 {
    margin-right: -105px;
  }
  .banner-area {
    position: relative;
    background: url(assets/image/HomePage/banner-img1.jpeg);
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 623px;
    display: none;
  }
  .home-process-area .single-process span::before {
    content: "";
    position: absolute;
    content: "";
    height: 67px;
    border-right: 1px dashed #f5faff;
    transform: rotate(90deg);
    top: -1px;
    left: 43.3px;
    z-index: -1;
  }
  .home-process-area .single-process span img {
    max-width: 25px;
    margin-left: 32px;
    margin-top: 2px;
    animation: leftani 5s infinite linear;
  }
  #banner-area1 {
    position: relative;
    background: url(assets/image/HomePage/bannerimgmini.png);
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 500px;
    display: block;
  }
  .box-btn {
    padding: 10px 10px;
    margin-top: 9px;
  }
  .header-area .header-content-right .header-social {
    padding-top: 13px;
  }
  .consult_cycle {
    max-height: 55px;
    max-width: 100%;
  }
}
#text1 {
  font-family: var(--headingFontFamily);
  font-weight: 700;
  color: #f5faff;
}

#our1 {
  margin-bottom: 0;
  text-align: center;
}

@media (max-width: 820px) and (min-width: 576px) {
  #future {
    margin-left: -120px;
  }
}
@media (max-width: 767px) and (min-width: 200px) {
  .who_we_are {
    max-width: 100%;
    margin-top: -47px;
    margin-bottom: -7px;
  }
  #who1 {
    text-align: center;
    padding-left: 2rem;
  }
  .service1 {
    text-align: center;
    margin-top: 15px;
    padding-bottom: 1px;
  }
  .single-client #testmoni1 {
    margin-top: 1.5rem;
  }
  .contact_us {
    max-width: 100%;
    height: 18rem;
  }
  .home-company-area {
    padding-top: 25px;
    padding-bottom: 50px;
  }
  .referral_us {
    max-width: 100%;
    height: 30.4rem;
    padding-top: 1rem;
    padding-left: 8px;
  }
  .why_choose_us {
    width: 100%;
    height: 20rem;
    padding-bottom: 1.5rem;
  }
  .ask_us {
    max-width: 100%;
    height: 18rem;
  }
  .single-client {
    height: 21rem;
  }
  .single-client2 {
    height: 22rem;
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .single-client img {
    position: absolute;
    top: -27px;
  }
  .consult_cycle {
    max-height: 55px;
    max-width: 100%;
  }
  .comming-soon-item .subscribe-form .form-group .btn-wrapper .boxed-btn {
    border-radius: 0;
    padding: 20px 11px 20px 6px;
    font-weight: 700;
    text-transform: uppercase;
    background: #051242;
    color: white;
    font-size: 11px;
    margin-right: -51px;
  }
  .page-title-area {
    padding-top: 50px;
    padding-bottom: 75px;
  }
  .footer-area .copy-area p {
    font-size: 15px;
    color: #f5faff;
    margin-bottom: 0;
    padding-left: 3rem;
  }
  #Kreativ3D {
    margin-top: -55px;
    height: 50%;
  }
  #zeqube {
    margin-top: 20px;
  }
  .ptb-100 {
    padding-top: 15px;
    padding-bottom: 50px;
  }
  .about_image {
    max-width: 100%;
    height: 20rem;
    margin-top: 30px;
    display: none;
  }
  .single-service .service-img .we_do_img {
    width: 40%;
  }
  .single-service1 .service-img .we_do_img {
    width: 40%;
  }
}
@media (min-width: 350px) and (max-width: 767px) {
  .navbar-area .active .collapse:not(.notshow) {
    display: block;
    margin-left: -44%;
    padding-left: 3%;
    margin-right: -10%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .single-client {
    background-color: var(--whiteColor);
    padding: 30px;
    position: relative;
    text-align: center;
    margin-top: 30px;
    height: 16rem;
  }
  .testmoni2 {
    padding-top: 2rem;
  }
  #Kreativ3D {
    margin-top: -55px;
    height: 11rem;
  }
  .footer-area .copy-area p {
    color: #f5faff;
    margin-bottom: 0;
    padding-left: 23rem;
  }
  .footer-area .content .foot-social {
    position: relative;
    padding-left: 17px;
  }
  .single-service1 {
    height: 30rem;
  }
  .list_items {
    text-align: left;
    padding-left: 30px;
    list-style-type: disc;
  }
  h3 {
    font-size: 21px;
    line-height: 1.5;
  }
  .footer-area .content h3 {
    font-size: 17px;
  }
  #zeqube {
    margin-top: 21px;
  }
  .comming-soon-item .subscribe-form .form-group .form-control {
    width: 21rem;
  }
  .comming-soon-item .subscribe-form .form-group .btn-wrapper .boxed-btn {
    margin-left: -29.8rem;
  }
}
@media (min-width: 990px) and (max-width: 1200px) {
  .who_we_are {
    height: 24rem;
    margin-bottom: 5rem;
  }
  .home-process-area .single-process span::before {
    content: "";
    position: absolute;
    content: "";
    height: 112px;
    border-right: 1px dashed #f5faff;
    transform: rotate(90deg);
    top: -24px;
    left: 67.3px;
    z-index: -1;
  }
  .home-process-area .single-process span img {
    max-width: 100px;
    margin-left: 38px;
    animation: leftani 5s infinite linear;
  }
}
@media (max-width: 1440px) and (min-width: 1200px) {
  .footer-area .copy-area p {
    padding-left: 28rem;
  }
  .single-client {
    background-color: var(--whiteColor);
    padding: 30px;
    position: relative;
    text-align: center;
    margin-top: 30px;
    height: 19rem;
  }
  #Kreativ3D {
    margin-top: -25px;
  }
  .footer-area .content .footer-list {
    margin: 13px;
  }
  .footer-area .content h3 {
    font-size: 17px;
  }
  .list_items {
    text-align: left;
    padding-left: 30px;
    list-style-type: disc;
  }
  h3 {
    font-size: 21px;
    line-height: 1.5;
  }
  .single-service1 {
    height: 30rem;
  }
}
@media (max-width: 991px) and (min-width: 767px) {
  #Kreativ3D {
    margin-top: -41px;
    height: 9rem;
  }
  #zeqube {
    margin-top: 14px;
    height: 1.8rem;
  }
  .single-client {
    background-color: var(--whiteColor);
    padding: 30px;
    position: relative;
    text-align: center;
    margin-top: 30px;
    height: 13rem;
  }
  .footer-area .copy-area p {
    padding-left: 15rem;
  }
  .single-service1 {
    height: 28.5rem;
  }
  .list_items {
    text-align: left;
    padding-left: 30px;
    list-style-type: disc;
  }
  h3 {
    font-size: 21px;
    line-height: 1.5;
  }
  .single-service1 {
    height: 25rem;
  }
  .logo {
    display: none;
  }
  .header-area .header-content-right .header-contact {
    margin-left: -6rem;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  .home-process-area .single-process span::before {
    content: "";
    position: absolute;
    content: "";
    height: 81px;
    border-right: 1px dashed #f5faff;
    transform: rotate(90deg);
    top: -8px;
    left: 52.3px;
    z-index: -1;
  }
}
@media (max-width: 991px) and (min-width: 766px) {
  .why_choose_us {
    width: 100%;
    padding-bottom: 2rem;
    height: 30rem;
  }
}
@media (max-width: 2900px) and (min-width: 1201px) {
  .why_choose_us {
    width: 100%;
    height: 27.5rem;
  }
  .single-client #testmoni1 {
    margin-top: 0.3rem;
  }
}
@media (max-width: 1200px) and (min-width: 991px) {
  .why_choose_us {
    width: 100%;
    height: 31.7rem;
  }
  p {
    font-size: 12px;
  }
  .single-service {
    padding: 20px;
    height: 21.5rem;
  }
  .footer-area .content .subscribe button {
    position: absolute;
    top: -9.5px;
    height: 52px;
  }
  .banner-area {
    position: relative;
    background: url(assets/image/HomePage/banner-img1.jpeg);
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 623px;
    display: none;
  }
  #banner-area1 {
    position: relative;
    background: url(assets/image/HomePage/bannerimgtab1.png);
    background-size: cover;
    overflow: hidden;
    width: 100%;
    height: 604px;
    display: block;
  }
  .navbar-area .main-nav .navbar .navbar-nav .nav-item {
    margin-left: -7px;
  }
  .header-area .header-content-right {
    position: relative;
    display: inline-flex;
    margin-left: -3rem;
  }
  .box-btn {
    margin-top: 8px;
  }
  .header-area .header-content-right .header-social {
    padding-top: 8px;
  }
}
@media (min-width: 767px) and (max-width: 576px) {
  .single-service1 {
    position: relative;
    text-align: center;
    padding: 23px;
    margin-bottom: 17px;
    height: 28.5rem;
    transition: var(--transition);
    border-radius: var(--borderRadius);
    z-index: 1;
    overflow: hidden;
    box-shadow: var(--boxShadow);
  }
}
#title1 h3:hover {
  color: #0c38eb;
}

.single-service1:hover li {
  color: var(--whiteColor);
}

#we-do {
  text-align: center;
}

@media (max-width: 767px) and (min-width: 200px) {
  #who-we1 {
    text-align: left;
    padding-left: 2rem;
  }
  .home-company-area .company-content .box-btn {
    margin-top: 30px;
  }
}
.Conditions {
  padding-top: 5rem;
}

.Conditions ol {
  font-size: 10px;
  padding-top: 1px;
}

.Conditions p {
  font-size: 10px;
}

#refer1 p {
  font-size: 1rem;
}

#heading1 {
  text-align: center;
  padding-top: 1.5rem;
}

#wha {
  margin-left: -2px;
  margin-top: -5px;
}

#wha1 {
  margin-left: 4px;
  margin-top: -6px;
}

#wha2 {
  margin-left: -13px;
  margin-top: -7px;
}

#wha3 {
  margin-left: -0.5vh;
  margin-top: -5px;
}

@media (max-width: 767px) and (min-width: 200px) {
  .home-process-area .single-process span #img1 {
    max-width: 22px;
    margin-left: -36.5px;
    margin-top: 131.5px;
    display: block;
    animation: leftani 5s infinite linear;
  }
}
@media (max-width: 767px) and (min-width: 200px) {
  .home-process-area .single-process span img {
    max-width: 100px;
    margin-left: -50px;
    margin-top: 95px;
    display: none;
    animation: leftani 5s infinite linear;
  }
}
@media (max-width: 767px) and (min-width: 200px) {
  .home-process-area .single-process span #img1 {
    max-width: 22px;
    margin-left: -36.5px;
    margin-top: 131.5px;
    display: block;
    animation: leftani 5s infinite linear;
  }
  .home-process-area .single-process {
    text-align: center;
    margin-bottom: 65px;
    position: relative;
  }
  .home-process-area .single-process span::before {
    content: "";
    position: absolute;
    content: "";
    height: 60px;
    border-right: 1px dashed #f5faff;
    transform: rotate(0deg);
    top: 113px;
    left: -25.7px;
    z-index: -1;
  }
  #wha {
    margin-left: -5px;
    margin-top: -15px;
  }
  #wha1 {
    margin-left: 4px;
    margin-top: -15px;
  }
  #wha2 {
    margin-left: 2px;
    margin-top: -15px;
  }
  #wha3 {
    margin-left: -0.5vh;
    margin-top: -15px;
  }
  @keyframes leftani {
    0% {
      transform: translatey(-15px);
    }
    50% {
      transform: translatey(15px);
    }
    100% {
      transform: translatey(-15px);
    }
  }
}
@media (max-width: 2900px) and (min-width: 1201px) {
  .home-process-area .single-process span #img1 {
    max-width: 100px;
    margin-left: -50px;
    margin-top: 95px;
    display: none;
    animation: leftani 5s infinite linear;
  }
}
@media (max-width: 991px) and (min-width: 576px) {
  .home-process-area .single-process span #img1 {
    max-width: 100px;
    display: none;
    margin-left: 65px;
    animation: leftani 5s infinite linear;
  }
}
#ser_item1 h3 {
  font-size: 19px;
}